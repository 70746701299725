import React, { useState } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { TbCategory } from "react-icons/tb";
// import { VscDashboard  } from "react-icons/vsc";

export default function Sidebar() {
  const active = () => {
    $(".metismenu-link").toggleClass("active");
  };

  return (
    <>
      <div className="sidebar-mobile-overlay"></div>
      <div
        className="app-sidebar bg-royal sidebar-text-light sidebar-shadow appear-done enter-done fade-in slide-right"
        id="sidebar"
      >
        <div className="app-sidebar__inner">
          <div className="metismenu vertical-nav-menu">
            <ul className="metismenu-container">
              <li className="metismenu-item">
                <Link
                  className="metismenu-link"
                  to="/"
                  style={{ fontWeight: "700", fontSize: "18px" }}
                >
                  {/* <i class="metismenu-icon fa-solid fa-gauge"></i> */}
                  {/* <VscDashboard/> */}
                  Dashboard
                </Link>
              </li>
            </ul>
          </div>

          <div
            className="panel-group"
            id="accordionMenu"
            role="tablist"
            aria-multiselectable="true"
          >

          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingTwo"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                  {/* <TbCategory/> */}
                  Collection Category
                </a>
              </div>
            </div>
            <div
              id="collapseTwo"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingTwo"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link
                      to="/add-collection-category"
                      className="metismenu-link"
                    >
                      Add Collection Category
                    </Link>
                  </li>
                </ul>
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link
                      to="/manage-collection-category"
                      className="metismenu-link"
                    >
                      Manage Collection Category
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* <div className="panel panel-default metismenu vertical-nav-menu">
              <div className="panel-heading metismenu-container" role="tab" id="headingThree">
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    <i class="metismenu-icon fa-brands fa-searchengin"></i>
                    Collection Type
                  </a>
                </div>
              </div>
              <div
                id="collapseThree"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingThree"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className='metismenu-item'>
                      <Link to="/add-collection-type" className='metismenu-link'>Add Collection Type</Link>
                    </li>


                  </ul>
                  <ul className="metismenu-container">
                    <li className='metismenu-item'>
                      <Link to="/manage-collection-type" className='metismenu-link'>Manage Collection Type</Link>
                    </li>
                  </ul>
                </div>

              </div>
            </div> */}

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingFour"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                  Collection
                </a>
              </div>
            </div>
            <div
              id="collapseFour"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingFour"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/add-collection" className="metismenu-link">
                      Add Collection
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/manage-collection" className="metismenu-link">
                      Manage Collection
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingFive"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                  Photography
                </a>
              </div>
            </div>
            <div
              id="collapseFive"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingFive"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/add-photography" className="metismenu-link">
                      Add Photography
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/manage-photography" className="metismenu-link">
                      Manage Photography
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/add-photo-gallery" className="metismenu-link">
                      Add Photo Gallery
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/manage-photo-gallery" className="metismenu-link">
                      Manage Photo Gallery
                    </Link>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingEleven"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseEleven"
                  aria-expanded="false"
                  aria-controls="collapseEleven"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  All Thematic Collection
                </a>
              </div>
            </div>
            <div
              id="collapseEleven"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingEleven"
            >
              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div
                    className="panel-heading metismenu-container"
                    role="tab"
                    id="headingNine"
                  >
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                        style={{ fontWeight: "700", fontSize: "medium" }}
                      >
                        {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                        Startups
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseNine"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingNine"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/edit-details" className="metismenu-link">
                            Edit Details
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link
                            to="/manage-startup-type"
                            className="metismenu-link"
                          >
                            Manage Type
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/add-type" className="metismenu-link">
                            Manage Collection
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div
                    className="panel-heading metismenu-container"
                    role="tab"
                    id="headingTen"
                  >
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                        style={{ fontWeight: "700", fontSize: "medium" }}
                      >
                        {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                        Education & Career
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseTen"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingTen"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link
                            to="/edit-details-education"
                            className="metismenu-link"
                          >
                            Edit Details
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link
                            to="/manage-education-type"
                            className="metismenu-link"
                          >
                            Manage Type
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/add-types" className="metismenu-link">
                            Manage Collection
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div
                    className="panel-heading metismenu-container"
                    role="tab"
                    id="headingSix"
                  >
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                        style={{ fontWeight: "700", fontSize: "medium" }}
                      >
                        {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                        Others
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseSix"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingSix"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link
                            to="/add-thematic-collection"
                            className="metismenu-link"
                          >
                            Add Thematic Collection
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link
                            to="/manage-thematic-collection"
                            className="metismenu-link"
                          >
                            Manage Thematic Collection
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingtwentyone"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseElevenn"
                  aria-expanded="false"
                  aria-controls="collapseElevenn"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Description
                </a>
              </div>
            </div>
            <div
              id="collapseElevenn"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingtwentyone"
            >
              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div
                    className="panel-heading metismenu-container"
                    role="tab"
                    id="headingNine"
                  >
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                        style={{ fontWeight: "700", fontSize: "medium" }}
                      >
                        {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                        Collection Desc
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseNine"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingNine"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link
                            to="/collection-desc"
                            className="metismenu-link"
                          >
                            Manage Desc
                          </Link>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>

              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div
                    className="panel-heading metismenu-container"
                    role="tab"
                    id="headingNinee"
                  >
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseNineee"
                        aria-expanded="false"
                        aria-controls="collapseNineee"
                        style={{ fontWeight: "700", fontSize: "medium" }}
                      >
                        {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                        Thematic Desc
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseNineee"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingNinee"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link
                            to="/thematic-desc"
                            className="metismenu-link"
                          >
                            Manage Desc
                          </Link>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div
                    className="panel-heading metismenu-container"
                    role="tab"
                    id="headingNineee"
                  >
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseNineeey"
                        aria-expanded="false"
                        aria-controls="collapseNineeey"
                        style={{ fontWeight: "700", fontSize: "medium" }}
                      >
                        {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                        Blog Desc
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseNineeey"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingNineee"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link
                            to="/blog-desc"
                            className="metismenu-link"
                          >
                            Manage Desc
                          </Link>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>

              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div
                    className="panel-heading metismenu-container"
                    role="tab"
                    id="headingNineeee"
                  >
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseNineeeye"
                        aria-expanded="false"
                        aria-controls="collapseNineeeye"
                        style={{ fontWeight: "700", fontSize: "medium" }}
                      >
                        {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                        FolkLore Desc
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseNineeeye"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingNineeee"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link
                            to="/folklore-desc"
                            className="metismenu-link"
                          >
                            Manage Desc
                          </Link>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

          {/* <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingNine"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Startups
                </a>
              </div>
            </div>
            <div
              id="collapseNine"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingNine"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/edit-details" className="metismenu-link">
                      Edit Details
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/add-type" className="metismenu-link">
                      Add Type
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingTen"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Education & Career
                </a>
              </div>
            </div>
            <div
              id="collapseTen"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingTen"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link
                      to="/edit-details-education"
                      className="metismenu-link"
                    >
                      Edit Details
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/add-types" className="metismenu-link">
                      Add Type
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}

          {/* <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingSix"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  Others
                </a>
              </div>
            </div>
            <div
              id="collapseSix"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingSix"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link
                      to="/add-thematic-collection"
                      className="metismenu-link"
                    >
                      Add Thematic Collection
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link
                      to="/manage-thematic-collection"
                      className="metismenu-link"
                    >
                      Manage Thematic Collection
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}

          {/* 
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div className="panel-heading metismenu-container" role="tab" id="headingNine">
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                    style={{ fontWeight: "700", fontSize: "medium"}}
                  >
                    <i class="metismenu-icon fa-brands fa-searchengin"></i>
                    Blog Category
                  </a>
                </div>
              </div>
              <div
                id="collapseNine"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingNine"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className='metismenu-item'>
                      <Link to="/add-blog-category" className='metismenu-link'>Add Blog Category</Link>
                    </li>
                  </ul>
                </div>
  
              </div>
            </div> */}

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingSeven"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                  Blog
                </a>
              </div>
            </div>

            <div
              id="collapseSeven"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingSeven"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/add-blog-category" className="metismenu-link">
                      Add/Manage Blog Category
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/add-blog" className="metismenu-link">
                      Add blog
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/manage-blog" className="metismenu-link">
                      Manage blog
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingEight"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                  Comments
                </a>
              </div>
            </div>
            <div
              id="collapseEight"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingEight"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/all-comments" className="metismenu-link">
                      All Comments
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingTwelve"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseTwelve"
                  aria-expanded="false"
                  aria-controls="collapseTwelve"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                  Banners
                </a>
              </div>
            </div>
            <div
              id="collapseTwelve"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingTwelve"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link to="/manage-banners" className="metismenu-link">
                      Manage Banners
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingEight"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapsethirteen"
                  aria-expanded="false"
                  aria-controls="collapsethirteen"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                  Register
                </a>
              </div>
            </div>
            <div
              id="collapsethirteen"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingthirteen"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link
                      to="/all-register-user-list"
                      className="metismenu-link"
                    >
                      All Registered Users
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingNine"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseeight"
                  aria-expanded="false"
                  aria-controls="collapsethirteen"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                  Distributor logo
                </a>
              </div>
            </div>
            <div
              id="collapseeight"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingeight"
            >
              <div className="panel-body">
                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link
                      to="/add-manage-contributer-logo"
                      className="metismenu-link"
                    >
                      Add / Manage Distributor logo
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* folk lore */}
          <div className="panel panel-default metismenu vertical-nav-menu">
            <div
              className="panel-heading metismenu-container"
              role="tab"
              id="headingTens"
            >
              <div className="panel-title metismenu-item">
                <a
                  className="collapsed metismenu-link"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordionMenu"
                  href="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                  style={{ fontWeight: "700", fontSize: "medium" }}
                >
                  {/* <i class="metismenu-icon fa-brands fa-searchengin"></i> */}
                  {/* <TbCategory/> */}
                  Folk Lore
                </a>
              </div>

            </div>

            <div
              id="collapseTen"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingTens"
            >
              <div className="panel-body">

                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link
                      to="/add-folklore"
                      className="metismenu-link"
                    >
                      Add tribe
                    </Link>
                  </li>
                </ul>

                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link
                      to="/manage-folklore"
                      className="metismenu-link"
                    >
                      Manage tribe
                    </Link>
                  </li>
                </ul>

                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link
                      to="/add-folkVolume"
                      className="metismenu-link"
                    >
                      Add & Manage Volume
                    </Link>
                  </li>
                </ul>


                <ul className="metismenu-container">
                  <li className="metismenu-item">
                    <Link
                      to="/manage-folklores"
                      className="metismenu-link"
                    >
                      Manage FolkLore
                    </Link>
                  </li>
                </ul>

              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

{
  /* <div className="panel panel-default metismenu vertical-nav-menu">
              <div className="panel-heading metismenu-container" role="tab" id="headingTwo">
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <i class="metismenu-icon fa-brands fa-searchengin"></i>
                    Marchent
                  </a>
                </div>
              </div>
              <div
                id="collapseTwo"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className='metismenu-item'>
                      <Link to="/add-marchent" className='metismenu-link'>Add & Manage Marchent</Link>
                    </li>


                  </ul>
                </div>
              </div>
            </div> */
}
