import React, { useEffect, useState } from "react";
import HomeService from "../../../Service/HomeService";
// import HttpClientXml from "../../Utils/HttpClientXml";
import { toast } from "react-hot-toast";
import ImageLoader from "../../../Loader/ImageLoader";
import { CheckFileDimension } from "../../CheckFileDimension";
import { useNavigate } from "react-router-dom";
import HttpClientXml from "../../../Utils/HttpClientXml";
import Swal from 'sweetalert2'
import Select from 'react-select';
import DataTable from "react-data-table-component";
import { TbSwitchVertical } from "react-icons/tb";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const AddFolkVolume = () => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [imageLoader, setImageLoader] = useState(false);
    const [loader, setLoader] = useState(true);
    const [folkloreCollection, setFolkloreCollection] = useState([]);

    const [tribeData, setTribeData] = useState([]);
    const initData = {
        "title": "",
        "subtitle": "",
        "smallDesc": "",
        "description": "",
        "tribeDesc": "",
        "tribeId": [],
        "image": ""
    }
    const [formData, setFormData] = useState(initData);

    const [isEdit, setIsEdit] = useState(false);
    const [singleData, setSingleData] = useState(false);
    const [selectTribrId, setSelectTribeId] = useState([]);

    console.log("formDatacfvv", formData)

    // handleChange
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }))
    }

    // get tribe data
    const getTribeData = async () => {
        const res = await HttpClientXml.requestData("folklore-get", {}, "GET");
        if (res && res?.status) {
            const arr = res?.data?.map(item => ({
                value: item?._id,
                label: item?.name
            }))
            setTribeData(arr)
        }
    }

    // image upload
    const imageHandler = async (e) => {
        // toast.success("Please upload images with a height of 260 pixels and a width of 300 pixels.")
        // console.log("IMAGESIZE", e);
        setImageLoader(true);
        let file = e.target.files[0];
        let data = new FormData();
        data.append("image", file);
        let res = await HttpClientXml.fileUplode(
            "upload-folklore-image",
            "POST",
            data
        );
        if (res && res.status) {
            toast.success("Image uploaded successfully");
            setFormData(prev => ({ ...prev, image: res?.doc?.url }))
            // setImage(res?.doc?.url);
            setImageLoader(false);
        } else {
            setImageLoader(false);
            toast.error("Failed to upload Image");
        }
    }

    const validate = () => {
        let error = {}
        if (!formData.title) {
            toast.error("Title is Required")
            return true
        }

        if (!formData.subtitle) {
            toast.error("Subtitle is Required")
            return true
        }

        if (!formData.smallDesc) {
            toast.error("Small Description is Required")
            return true
        }

        if (!formData.description) {
            toast.error("Description is Required")
            return true
        }

        if (!formData.tribeDesc) {
            toast.error("Tribe Description is Required")
            return true
        }

        if (!formData.image) {
            toast.error("Image is Required")
            return true
        }

        return false
    }


    const columns = [
        {
            name: <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>SL</div>,
            selector: (row) => row.sl,
            sortable: true,
            width: "80px",
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Title
                </div>
            ),
            selector: (row) => row.title,
        },
        {
            name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}>Subtitle</div>,
            selector: row => row.subtitle,
        },
        {
            name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}>Image</div>,
            selector: row => row.image,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: '#495057', marginLeft: "15px", fontWeight: "bolder" }}
                >
                    Action
                </div>
            ),
            selector: (row) => row.action,
        },
    ];

    const onDelete = async (id) => {
        let data = {
            "isDeleted": true
        }

        const del = async () => {
            const res = await HttpClientXml.requestData("folklore-volume/" + id, {}, "DELETE")
            if (res && res?.status) {
                toast.success("Data Deleted Successfully")
                fetchAllData()
            } else {
                toast.error(res?.message)
            }
        }
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                del()
                // Swal.fire({
                //     title: "Deleted!",
                //     text: "Your file has been deleted.",
                //     icon: "success"
                // });
            }
        });


    }

    // edit click
    const handleEditClick = (item) => {
        window.scrollTo(0, 0)
        setIsEdit(true)
        setSingleData(item)
        setFormData({
            "title": item?.title,
            "subtitle": item?.subtitle,
            "smallDesc": item?.smallDesc,
            "description": item?.description,
            "tribeDesc": item?.tribeDesc,
            "tribeId": item?.tribeId,
            "image": item?.image
        })

        const arr = item?.tribeId?.map(ele => {
            // console.log("eleedd", ele)
            return tribeData?.find(tr => {
                return tr.value === ele
            })
        })
        setSelectTribeId(arr)
        console.log("arrtribe", arr)
    }

    //   fetch all data
    const fetchAllData = async () => {
        const res = await HttpClientXml.requestData("folklore-volume", {}, "GET")
        // console.log("resdd", res)
        if (res && res?.status) {
            setLoader(false);
            let arr = res?.data?.map((item, index) => {
                return {
                    sl: index + 1,
                    title: item?.title,
                    subtitle: item?.subtitle,
                    image:
                        <>
                            {
                                (item?.image) ? <img style={{ height: '39%', width: '39%', borderRadius: '9px', margin: "5px" }} src={item?.image} /> :
                                    <img style={{ height: '11%', width: '11%', borderRadius: '9px', margin: "5px" }} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} />
                            }
                        </>,

                    action: (
                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                            <svg
                                // onClick={() => onEdit(item)}
                                onClick={() => {
                                    handleEditClick(item)

                                }}
                                style={{
                                    height: "20px",
                                    width: "20px",
                                    cursor: "pointer",
                                    marginRight: "20px",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                            >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                            </svg>
                            <svg
                                onClick={() => onDelete(item?._id)}
                                style={{
                                    color: "red",
                                    height: "20px",
                                    cursor: "pointer",
                                    width: "20px",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-trash3"
                                viewBox="0 0 16 16"
                            >
                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                            </svg>
                        </div>
                    ),
                };
            });
            setFolkloreCollection(arr);
        }
        else {
            console.log(res?.message);
            setLoader(false);
        }

    };

    // add volume
    const addVolume = async () => {
        if (validate()) {
            return
        }

        if (isEdit) {
            const res = await HttpClientXml.requestData("folklore-volume/" + singleData?._id, formData, "PUT")
            if (res && res?.status) {
                toast.success("Folk Volume Updated Successfully");
                fetchAllData()
                setFormData(initData)
                setSelectTribeId([])
            } else {
                toast.error(res?.meseage);
            }
        } else {
            const res = await HttpClientXml.requestData("folklore-volume", formData, "POST")
            if (res && res?.status) {
                toast.success("Folk Volume Added Successfully");
                fetchAllData()
                setFormData(initData)
                setSelectTribeId([])
            } else {
                toast.error(res?.message);
            }
        }
    };


    useEffect(() => {
        getTribeData()
    }, [])

    useEffect(() => {
        fetchAllData()
    }, [tribeData])

    return (
        <>
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            Add Folk Volume
                        </div>


                        {/* form part */}
                        <div className="row">

                            {/* Title */}
                            <div className="form-group col-md-6">
                                <label for="exampleInputEmail1">
                                    Title<span style={{ color: "red" }}>*</span> :
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    name="title"
                                    value={formData.title}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter Title..."
                                />
                            </div>

                            {/* Subtitle */}
                            <div className="form-group col-md-6">
                                <label for="exampleInputEmail1">
                                    Subtitle<span style={{ color: "red" }}>*</span> :
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    name="subtitle"
                                    value={formData.subtitle}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter Subtitle..."
                                />
                            </div>

                            {/* Small Description */}
                            <div className="form-group col-md-12">
                                <label for="exampleInputEmail1">
                                    Small Description<span style={{ color: "red" }}>*</span> :
                                </label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    name="smallDesc"
                                    value={formData.smallDesc}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter Small Description..."
                                />
                            </div>

                            {/* Description */}
                            {/* <div className="form-group col-md-12">
                                <label for="exampleInputEmail1">
                                    Description<span style={{ color: "red" }}>*</span> :
                                </label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    name="description"
                                    value={formData.description}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter Description..."
                                />
                            </div> */}


                            <div style={{ marginBottom: '21px' }}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={formData?.description}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log('Editor is ready to use!', editor);
                                    }}

                                    onChange={(event, editor) => {
                                        console.log("eee_event", event, editor)
                                        let data = editor.getData();

                                        // setDesc(data);
                                        setFormData(prev => ({ ...prev, description: data }))
                                    }}
                                />
                            </div>

                            {/* Tribe Description */}
                            <div className="form-group col-md-12">
                                <label for="exampleInputEmail1">
                                    Tribe Description<span style={{ color: "red" }}>*</span> :
                                </label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    name="tribeDesc"
                                    value={formData.tribeDesc}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter Tribe Description..."
                                />
                            </div>

                            {/* Select Tribe */}
                            <div className="form-group col-md-6">
                                <label for="exampleInputEmail1">
                                    Select Tribe<span style={{ color: "red" }}>*</span> :
                                </label>
                                {/* <input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter name..."
                                /> */}

                                <Select
                                    // defaultValue={[colourOptions[2], colourOptions[3]]}
                                    isMulti
                                    name="colors"
                                    options={tribeData}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={selectTribrId}
                                    onChange={e => {
                                        // console.log("edcc", e)
                                        const arr = e.map(ele => ele?.value)
                                        setFormData(prev => ({ ...prev, tribeId: arr }))
                                        setSelectTribeId(e)
                                    }}
                                />
                            </div>

                            {/* Image  */}
                            <div className="mb-3 col-md-6">
                                <label for="formFile" className="form-label">
                                    Upload Image<span style={{ color: "red" }}>*</span> :
                                </label>
                                <input
                                    id="images"
                                    onChange={imageHandler}
                                    className="form-control"
                                    accept="image/*"
                                    type="file"
                                />
                                <span>(374 × 187 px)</span>
                                {imageLoader ? (
                                    <>
                                        <ImageLoader />{" "}
                                    </>
                                ) : null}
                                {
                                    formData?.image && (
                                        <img
                                            style={{
                                                height: "30%",
                                                width: "30%",
                                                marginTop: "12px",
                                                borderRadius: "9px",
                                            }}
                                            src={formData?.image}
                                        />
                                    )}
                            </div>

                        </div>
                        {
                            <button className="btn btn-primary" onClick={addVolume}>
                                {isEdit ? "Update" : "Submit"}
                            </button>
                        }
                    </div>
                </div>
            </div>


            {/* table */}

            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        {/* {
                            loader ? <div style={{ textAlign: 'center' }}> <Loader /> </div> : null
                        } */}
                        <div style={{ textAlign: "center", fontSize: "20px", color: '#868e96', margin: '35px' }} className="card-title">
                            View Folk Volume
                        </div>
                        <DataTable
                            columns={columns}
                            data={folkloreCollection}
                            pagination
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddFolkVolume;
