import React, { useState } from "react";
import bannerimage from "../images/citynights.8df3ac24685306a68061.jpg";
import captcha from "../images/logo-betbazz365bet.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useDispatch } from "react-redux";
import { setUser } from "../Redux/Reducer/User";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import HomeService from "../Service/HomeService";
import Authservice from "../Service/Authservice";
import { reactLocalStorage } from "reactjs-localstorage";
import { FaEye, FaEyeSlash } from "react-icons/fa";
export default function Login() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [emailspan, setEmailSpan] = useState(false);

   const [showPasswords, setShowPasswords] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlelogin = async(event) => {
    
    // event.preventDefault();
    if (email == "") {
      return toast.error("Please Enter Email");
    }
    if (pass == "") {
      return toast.error("Please Enter Password");
    }
    let data = {
      email: email,
      password: pass,
    };

   
    try {
       let res = await Authservice?.login(data);
       if(res && res?.status) {
        toast.success("Logged In Successfully");
          dispatch(setUser(data));
          reactLocalStorage.set("loginStatus", true);
          reactLocalStorage.set("token", res?.data?.token);

          // navigate("/");
          window.location.href = "/";
       }else{
          toast.error(res?.message)
       }
    } catch (error) {
      console.log(error);
    }

    // Authservice.login(data)
    //   .then((res) => {
    //     console.log("res", res);
    //     if (res && res.status) {
    //       toast.success("Logged In Successfully");
    //       dispatch(setUser(data));
    //       reactLocalStorage.set("loginStatus", true);
    //       reactLocalStorage.set("token", res?.data?.token);

    //       // navigate("/");
    //       window.location.href = "/";
    //     } else {
    //       toast.error("Something wrong");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  return (
    <>
      <div className="h-100 g-0 row">
        <div className="d-none d-lg-block col-lg-5 col-md-5 col-12">
          <div
            className="slider-light"
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {/* <Slider {...settings}>
                            <div
                                data-index={0}
                                className="slick-slide"
                                tabIndex={-1}
                                aria-hidden="true"
                                style={{
                                    outline: "none",
                                    width: 455,
                                    position: "relative",
                                    left: 0,
                                    opacity: 0,
                                    transition: "opacity 500ms ease 0s, visibility 500ms ease 0s"
                                }}
                            >
                                <div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-plum-plate"
                                        tabIndex={-1}
                                        style={{ width: "100%", display: "inline-block" }}
                                    >
                                        <div
                                            className="slide-img-bg blink waitingForConnection "
                                            style={{ backgroundImage: `url('${bannerimage}')` }}
                                        />
                                        <div className="slider-content">
                                            <h3>Perfect Balance</h3>
                                            <p>
                                                ArchitectUI is like a dream. Some think it's too good to
                                                be true! Extensive collection of unified React Boostrap
                                                Components and Elements.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                data-index={1}
                                className="slick-slide slick-active slick-current"
                                tabIndex={-1}
                                aria-hidden="false"
                                style={{
                                    outline: "none",
                                    width: 455,
                                    position: "relative",
                                    left: "-455px",
                                    opacity: 1,
                                    transition: "opacity 500ms ease 0s, visibility 500ms ease 0s"
                                }}
                            >
                                <div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark"
                                        tabIndex={-1}
                                        style={{ width: "100%", display: "inline-block" }}
                                    >
                                        <div
                                            className="slide-img-bg blink waitingForConnection2"
                                            style={{ backgroundImage: `url('${bannerimage}')` }}
                                        />
                                        <div className="slider-content">
                                            <h3>Scalable, Modular, Consistent</h3>
                                            <p>
                                                Easily exclude the components you don't require.
                                                Lightweight, consistent Bootstrap based styles across all
                                                elements and components
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                data-index={2}
                                className="slick-slide"
                                tabIndex={-1}
                                aria-hidden="true"
                                style={{
                                    outline: "none",
                                    width: 455,
                                    position: "relative",
                                    left: "-910px",
                                    opacity: 0,
                                    transition: "opacity 500ms ease 0s, visibility 500ms ease 0s"
                                }}
                            >
                                <div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning"
                                        tabIndex={-1}
                                        style={{ width: "100%", display: "inline-block" }}
                                    >
                                        <div
                                            className="slide-img-bg blink waitingForConnection3 "
                                            style={{ backgroundImage: `url('${bannerimage}')` }}
                                        />
                                        <div className="slider-content">
                                            <h3>Complex, but lightweight</h3>
                                            <p>
                                                We've included a lot of components that cover almost all
                                                use cases for any type of application.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Slider> */}

            <img
              style={{ width: "100%", height: "100%" }}
              // src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=612,h=560,fit=crop/AVLDKzRX1VIVzR2l/vipin-joseph-hbwqgmdwtle-unsplash-A1ag0Jj4g6F34DRg.jpg"
               src="https://www.adotrip.com/public/images/state/master_images/5f215a8f6c2a7-Nagaland_Attractions.jpg"
              alt="..."
            />

            <div
              className="overtext"
              style={{
                position: "absolute",
                top: "10%",
                left: "10%",
                zIndex: "11",
              }}
            >
              <h3
                style={{
                  maxWidth: "315px",
                  fontSize: "36px",
                  textAlign: "center",
                  color: "red",
                  fontWeight: "700",
                }}
              >
                DIGITAL Library Nagaland
              </h3>
            </div>
          </div>
        </div>
        <div className="h-100 d-flex bg-white justify-content-center align-items-center col-md-7 col-lg-7 col-12">
          <div className="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9 rightCntlogin">
            {/* <div className="app-logo" /> */}
            <div
              className="logo_"
              style={{ color: "#adb5bd", fontSize: "23px" }}
            >
              {" "}
              Welcome,{" "}
            </div>
            <div className="logo_" style={{ color: "#868e96" }}>
              Please Sign In to your account
            </div>
            {/* <h4 className="mb-0">
                            <div>Welcome back,</div>
                            <span>Please sign in to your account.</span>
                        </h4>
                        <h6 className="mt-3">
                            No account?{" "}
                            <a href="https://colorlib.com/" className="text-primary">
                                Sign up now
                            </a>
                        </h6> */}
            <div className="divider row" />
            <div>
              {/* <form className=""> */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="exampleEmail" className="form-label">
                        Email
                      </label>
                      {emailspan && (
                        <span style={{ color: "red" }}>
                          * please provide us your email
                        </span>
                      )}
                      <input
                        name="email"
                        id="exampleEmail"
                        placeholder="Email here..."
                        type="email"
                        className="form-control"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        onBlur={(state) => {
                          if (email === "") {
                            setEmailSpan(true);
                          } else {
                            setEmailSpan(false);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="examplePassword" className="form-label">
                        Password
                      </label>
                      <div style={{ display:"flex" , }}>
                                    <input
                        name="password"
                        id="examplePassword"
                        placeholder="Password here..."
                        type={showPasswords ? 'text' : 'password'}
                        className="form-control"
                        onChange={(e) => setPass(e.target.value)}
                      />
                      <button
                        style={{
                          padding: "6px",
                          border: "none",
                          // margin: "35px -23px",
                        }}
                        className="eyecon"
                        onClick={() => setShowPasswords(!showPasswords)}
                      >
                        {showPasswords ? <FaEye /> : <FaEyeSlash />}
                      </button>
                      </div>
            
                    </div>
                  </div>
                </div>
                <div className="divider row" />
                <div className="d-flex align-items-center">
                  <div className="ms-auto">
                    {/* <a href="https://colorlib.com/" className="btn-lg btn btn-link">
                                            Recover Password
                                        </a>{" "} */}
                    <button className="btn btn-primary btn-lg"  onClick={handlelogin}>
                      Login to Dashboard
                    </button>
                  </div>
                </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
