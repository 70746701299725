import React, { useEffect, useState } from 'react'
import Table from '../../Component/Table'
import HomeService from '../../Service/HomeService';

import { toast } from 'react-hot-toast';
export default function Index() {


  const [totalCollection , setTotalCollection] = useState(null);
  const [totalTheme , setTotalTheme] = useState(null);
  const [totalBlog , setTotalBlog] = useState(null);
  const [totalSubscribedUser , setTotalSubscripedUser] = useState(null)


  useEffect(() => {
    fetchAllCollection(); 
    FetchAllTheme();
    FetchAllBlog();
    fetchAllSubscribedUsers();
  })

  const fetchAllCollection = () => {
    HomeService.getAllCollection()
    .then((res) => {
      console.log("AllColl",res);
      if(res && res.status) {
        console.log("AllColl",res);
        setTotalCollection(res?.data?.length)
        // fetchAllColl();
      }else{
        toast.error(res?.message)
      }
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const FetchAllTheme = () => {
    HomeService.AllThematicCollection()
    .then((res) => {
      console.log("AllTheme",res);
      if(res && res.status) {
        console.log("AllColl",res);
        setTotalTheme(res?.data?.length)
        // fetchAllColl();
      }else{
        toast.error(res?.message)
      }
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const FetchAllBlog = () => {
    HomeService.ManageBlog()
    .then((res) => {
      console.log("AllBlog",res);
      if(res && res.status) {
        console.log("AllColl",res);
        setTotalBlog(res?.data?.length)
        // fetchAllColl();
      }else{
        toast.error(res?.message)
      }
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const fetchAllSubscribedUsers = () => {
    HomeService.ViewAllRegisterList()
    .then((res) => {
      console.log("AllTheme",res);
      if(res && res.status) {
        console.log("AllColl",res);
        setTotalSubscripedUser(res?.data?.length)
        // fetchAllColl();
      }else{
        toast.error(res?.message)
      }
    })
    .catch((err) => {
      console.log(err);
    })
  }


  return (


    <>
      <div className=''>
        <div className=''>
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="card-shadow-danger mb-3 widget-chart widget-chart2 text-start card">
                <div className="widget-content">
                  <div className="widget-content-outer">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left pe-2 fsize-1">
                        <div className="widget-numbers mt-0 fsize-3 text-danger">{totalCollection}</div>
                      </div>
                      {/* <div className="widget-content-right w-100">
                        <div className="progress-bar-xs progress">
                          <div
                            className="progress-bar bg-danger"
                            role="progressbar"
                            aria-valuenow={75}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            style={{ width: "71%" }}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="widget-content-left fsize-1">
                      <div className="text-muted opacity-6">Total Collections</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="card-shadow-success mb-3 widget-chart widget-chart2 text-start card">
                <div className="widget-content">
                  <div className="widget-content-outer">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left pe-2 fsize-1">
                        <div className="widget-numbers mt-0 fsize-3 text-success">
                          {totalTheme}
                        </div>
                      </div>
                      {/* <div className="widget-content-right w-100">
                        <div className="progress-bar-xs progress">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow={54}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            style={{ width: "54%" }}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="widget-content-left fsize-1">
                      <div className="text-muted opacity-6">Total Themes</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="card-shadow-warning mb-3 widget-chart widget-chart2 text-start card">
                <div className="widget-content">
                  <div className="widget-content-outer">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left pe-2 fsize-1">
                        <div className="widget-numbers mt-0 fsize-3 text-warning">
                          {totalBlog}
                        </div>
                      </div>
                      {/* <div className="widget-content-right w-100">
                        <div className="progress-bar-xs progress">
                          <div
                            className="progress-bar bg-warning"
                            role="progressbar"
                            aria-valuenow={32}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            style={{ width: "32%" }}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="widget-content-left fsize-1">
                      <div className="text-muted opacity-6">Total Blogs</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="card-shadow-info mb-3 widget-chart widget-chart2 text-start card">
                <div className="widget-content">
                  <div className="widget-content-outer">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left pe-2 fsize-1">
                        <div className="widget-numbers mt-0 fsize-3 text-info">{totalSubscribedUser}</div>
                      </div>
                      {/* <div className="widget-content-right w-100">
                        <div className="progress-bar-xs progress">
                          <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            aria-valuenow={89}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            style={{ width: "89%" }}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="widget-content-left fsize-1">
                      <div className="text-muted opacity-6"> Total Registered Users</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

         

        </div>

      </div>
     {/* <Table /> */}

    </>


  )
}
