import React, { useEffect, useState } from "react";
import HomeService from '../../Service/HomeService';
import HttpClientXml from "../../Utils/HttpClientXml";
import { toast } from "react-hot-toast";
import ImageLoader from "../../Loader/ImageLoader";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";

const ManageType = () => {
  const [name , setName] = useState('');
  const [description , setDescription] = useState('')


  const [id , setId] = useState('')

  const[hide , setHide] = useState(true)

  const [themeId , setThemeId] = useState('');
  const [themeData , setThemeData] = useState([])


  const [educationData , setEducationData] = useState([])


  const [theme , setTheme]= useState('')

  console.log(description)
  const [image , setImage] = useState('');
  const [imageLoader , setImageLoader] = useState(false)
  console.log("Image",image);




   useEffect(() => {
    fetchAllTheme();
    
  },[]);

  useEffect(()=> {
    FetchAllType()
  },[theme])




      const FetchAllType = () => {
        if(theme){
        HomeService.viewEduStartupType(theme)
        .then((res) => {
            console.log("ResAllDATA" , res.data);
          if (res && res?.status) {
            //  setLoader(false)
              let arr = res?.data?.map((item, index) => {
            return {
              sl: index + 1,
              typeName: item?.typeName,
              description: item?.description,
              Image:
              <>
              {
                (item?.image) ? <img style={{ height: '39%', width: '39%' , borderRadius:'9px' , margin:"5px" }} src={item?.image} /> :
                <img style={{ height: '11%', width: '11%' , borderRadius:'9px' , margin:"5px" }} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} />
              }
              </>,

              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <svg
                    onClick={() => onDelete(item?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </div>
              ),
            };
          });
          setEducationData(arr);
          }
          console.log("RESPONSE", res);
        })
        .catch((err) => {
        //   setLoader(false)
          console.log("err", err);
        });
      }
    }


    const onDelete = (id) => {
      console.log("Id" , id);
  
      Swal.fire({
          title: 'Are you sure?',
          // text: "You won't  to delete this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            console.log("hhhhhhhhhhhhhhhhhhhhhhhhh");
              HomeService.DeleteEduStartup(id)
              .then((res) => {
                console.log("DELETEEdu", res);
                if(res && res.status) {
                  toast.success("Deleted Successfully");
                  console.log("RESPONSEDELETE",res);
                  fetchAllTheme();
                  FetchAllType();
                }else{
                  toast.error("Failed to Delete the Item ");
                }
              })
              .catch((err) => {
                console.log(err);
              })
          }
        })
  }



      const onEdit = (item) => {
        console.log("item",item);
                 setName(item?.typeName);
            // setThemeId("")
            setDescription(item?.description);
            setId(item?._id)
            setImage(item?.image);
        setHide(false);    
    }


   const columns = [
    {
      name: <div style={{ fontSize: "14px",color:'#495057', fontWeight: "bolder" }}>SL</div>,
      selector: (row) => row.sl,
      sortable: true,
      width:"80px",
    },
    {
      name: (
        <div style={{ fontSize: "14px", color:'#495057',  fontWeight: "bolder" }}>
          Type Name
        </div>
      ),
      selector: (row) => row.typeName,
    },
    {
      name: <div style={{ fontSize:'14px' ,color:'#495057', fontWeight:'bolder'}}> Image</div>,
      selector: row => row.Image,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px",color:'#495057', marginLeft: "15px", fontWeight: "bolder" }}
        >
          Description
        </div>
      ),
      selector: (row) => row.description,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color:'#495057', marginLeft: "15px", fontWeight: "bolder" }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];


  const fetchAllTheme = () =>{
    // let arr=[];
    HomeService.AllThematicCollection()
    .then((res) => {
        console.log("ResAllTheme" , res.data);
      if (res && res?.status) {
          res?.data.map((item) => {
              if(item?.name === "Startups in Nagaland" ){
                setTheme(item?._id)
                  // arr.push(item)
              }
          })
        
        // setThemeData(arr);
      }
      console.log("RESPONSE", res);
    })
    .catch((err) => {
    //   setLoader(false)
      console.log("err", err);
    });
  }

const imageHandler = async (e) => {
  toast.success("Please upload images with a height of 210 pixels and a width of 308 pixels.")
  setImageLoader(true)
  let file = e.target.files[0];
  let data = new FormData();
  data.append("image", file);



  let res = await HttpClientXml.fileUplode("upload-Photocategory" , "POST" , data);
  if(res && res.status){
    toast.success("Image uploaded successfully");

    setImage(res?.doc?.url);
    setImageLoader(false)
  }else{
      setImageLoader(false)
    toast.error("Failed to upload Image")
  }
};

const handleTheme = (e) => {
  setThemeId(e.target.value);
}


const OnUpdate = () => {
  let data = {
    themeId:  theme,
    typeName:name,
    description:description,
    image:image
  }
  if( theme && name && description && image){
    HomeService.UpdateEduStartup(id , data)
    .then((res) => {
        console.log("Response Edit" , res);
        if(res && res.status){
            toast.success("Updated Successfully");

            setHide(true);
            setName("");
            setThemeId("")
            setDescription("");
            setImage("");
            let file = document.querySelector("#images");
            file.value = "";
             fetchAllTheme();
              FetchAllType()
        }else{
          toast.error(res?.message);
        }
    })
    .catch((err) => {
        console.log(err);
    })
  }else{
    toast.error("All Fields Are Required")
  }
}

const AddStartupType = () => {
  let data = {
    themeId:  theme,
    typeName:name,
    description:description,
    image:image
  }
  if(theme && name && description && image){
    HomeService.AddEducationType(data)
    .then((res) => {
        console.log("Response Add" , res);
        if(res && res.status){
            toast.success("Added Successfully");
            setName("");
            setThemeId("");
            setDescription("");
            setImage("");
            let file = document.querySelector("#images");
            file.value = "";

            fetchAllTheme();
            FetchAllType();
            
        }else{
          toast.error(res?.message);
        }
    })
    .catch((err) => {
        console.log(err);
    })
  }else{
    toast.error("All Fields Are Required")
  }
}



  return (
    <div component="div" className="TabsAnimation appear-done enter-done">
    <div className="main-card mb-3 card">
      <div className="card-body">

        {
          hide ?    <div style={{ textAlign: "center" , fontSize: "20px" , color:'#868e96',  margin:'35px'}} className="card-title">
          Add Type
        </div>

        : 
              <div style={{ textAlign: "center" , fontSize: "20px" , color:'#868e96',  margin:'35px'}} className="card-title">
          Edit Type
        </div>
        }
     
        


{/* 
        <label for="exampleInputEmail1">
          Theme Name<span style={{ color: "red" }}>*</span> :
        </label> */}

        {/* <select
          style={{ marginBottom: "21px" }}
          class="form-select"
          aria-label="select category"
          value={themeId}
          onChange={(e) => handleTheme(e)}
        >
          <option value={""}>Select a theme name.......</option>
          {themeData.map((item) => {
            return (
              <option id={item?._id} value={item?._id}>
                {item?.name}
              </option>
            );
          })}
        </select> */}


        <div class="form-group">
          <label for="exampleInputEmail1">Type Name<span style={{ color:'red'}}>*</span> :</label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter name..."
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Description<span style={{ color:'red'}}>*</span> :</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            class="form-control"
            id="exampleInputPassword1"
            placeholder="Enter description..."
          />
        </div>

        <div class="mb-3">
          <label for="formFile" class="form-label">
            Thumbnail Image<span style={{ color:'red'}}>*</span> :
          </label>
          <input
            id="images"
            onChange={imageHandler}
            class="form-control"
            accept="image/*"
            type="file"
          />
          {imageLoader ? <><ImageLoader/> </>  :null}
          {image && <img style={{ height: "20%", width: "20%" , marginTop:'12px' , borderRadius:'9px' }} src={image} />}
        </div>
          {
            hide ?  <button  class="btn btn-primary" onClick={AddStartupType}>
          Submit
        </button> 
        :
                <button  class="btn btn-primary" onClick={OnUpdate}>
          Update
        </button>
          }
       

           <div style={{ textAlign: "center" , fontSize: "20px" , color:'#868e96',  margin:'35px'}} className="card-title">
     Manage Type
   </div>
   <DataTable columns={columns} data={educationData}   pagination/>
      </div>
    </div>
  </div>
  )
}

export default ManageType