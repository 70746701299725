import React, { useEffect, useState } from "react";
import HomeService from '../../Service/HomeService';
import HttpClientXml from "../../Utils/HttpClientXml";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import ImageLoader from "../../Loader/ImageLoader";

const EditCollectionCategory = () => {



  const [name , setName] = useState('');
  const [description , setDescription] = useState('');
  const [image , setImage] = useState('');
  const [id , setId] = useState('')

  const [imgLoader , setImageLoader] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();


  const imageHandler = async (e) => {
    setImageLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);

    // let res = await HttpClientXml.fileUplode(
    //   "upload-collection-document",
    //   'POST',
    //   file
    // );

    let res = await HttpClientXml.fileUplode("upload-Photocategory" , "POST" , data)
    if(res && res.status){
      toast.success("Image Upload Successfully");
      setImage(res?.doc?.url);
      setImageLoader(false);
    }else{
      toast.error("Failed to upload Image")
      setImageLoader(false);
    }
  };

useEffect(() => {
    if(location?.pathname == '/edit-collection-category'){
        setName(location?.state?.name);
        setDescription(location?.state?.desc);
        setId(location?.state?._id);
        setImage(location?.state?.image)
    }
}, [])

const EditCollCategory = () => {
  let data = {
      name:name,
      desc:description,
      image:image
  }
  if(name && description){
    HomeService.EditCollectionCategory(id,data)
    .then((res) => {
        console.log("Response Edit" , res);
        if(res && res.status){
            toast.success("Collection Category updated successfully");
            setName("");
            setDescription("");
            let file = document.querySelector("#images");
            file.value = "";
            setImage("");
            navigate('/manage-collection-category')
        }else{
          toast.error(res?.message)
        }
    })
    .catch((err) => {
        console.log(err);
    })
  }else{
    toast.error("All Fields Are Required")
  }
}

  return (
   <>
         <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div style={{ textAlign: "center" ,fontSize: "20px" , color:'#868e96',  margin:'35px'}} className="card-title">
              Edit Collection Category
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Name :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter name"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Description :</label>
              <input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Enter description"
              />
            </div>

            <div class="mb-3">
              <label for="formFile" class="form-label">
                Upload Image :
              </label>
              <input
                id="images"
                onChange={imageHandler}
                class="form-control"
                type="file"
                accept="image/*"
              />
              {imgLoader ? <ImageLoader/> : null}
              {image && <img style={{ height: "30%", width: "30%" , marginTop:'12px' , borderRadius:'9px' }} src={image} />}
            </div>

            <button  class="btn btn-primary" onClick={EditCollCategory}>
              Submit
            </button>
          </div>
        </div>
      </div>
   </>
  )
}

export default EditCollectionCategory