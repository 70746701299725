import React, { useEffect, useState } from "react";
import HomeService from "../../Service/HomeService";
import HttpClientXml from "../../Utils/HttpClientXml";
import { toast } from "react-hot-toast";
import ImageLoader from "../../Loader/ImageLoader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CheckFileDimension } from "../CheckFileDimension";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
const INITIAL = {
  flokLoreId: "",
  name: "",
  narrator: "",
  age: "",
  gender: "",
  village: "",
  jobProfile: "",
  Illustrator: "",
  // description: "",
  smallDescription: "",
  image1: "",
  // image2: "",
  subtitle: "",
  extraNarrator: [],
  flokLoreVolumeId: "",
  readTime: ""
};

export const Manage = () => {
  const [image, setImage] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [imageLoader1, setImageLoader1] = useState(false);
  const [flokloreCategoryData, setflokloreCategoryData] = useState([]);
  const [flokloreCollectionData, setflokloreCollectionData] = useState([]);
  const [id, setId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [show, setShow] = useState(true);
  const [desc, setDesc] = useState("");
  const [flokloreData, setFlokloreData] = useState(INITIAL);
  const [flokloreVolume, setFlokloreVolume] = useState([]);

  console.log("flokloreDatadfgvv", flokloreData);


  const gteFolkLoreVolume = async () => {
    const res = await HttpClientXml.requestData("folklore-volume", {}, "GET")
    if (res && res?.status) {
      setFlokloreVolume(res?.data)
    }
  }

  const ViewFolkloreCollection = () => {
    HomeService?.viewFolklorecollection()
      .then((res) => {
        console.log("COLLECTIOn", res?.data);
        let arr = res?.data?.map((item, inx) => {
          return {
            sl: inx + 1,
            flokLoreName: item?.flokloredata?.name,
            volume: item?.flokloreVolume?.title,
            name: item?.name,
            // narrator: item?.narrator,
            // village: item?.village,
            subtitle: item?.subtitle,
            formValues: item?.extraNarrator,
            image1: (
              <>
                {item?.image1 ? (
                  <>
                    <img
                      style={{
                        height: "55%",
                        width: "55%",
                        borderRadius: "14px",
                        margin: "5px",
                      }}
                      src={item?.image1}
                    />
                  </>
                ) : (
                  <img
                    style={{
                      height: "35%",
                      width: "35%",
                      borderRadius: "14px",
                      margin: "5px",
                    }}
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
                    }
                  />
                )}
              </>
            ),

            image2: (
              <>
                {item?.image2 ? (
                  <>
                    <img
                      style={{
                        height: "55%",
                        width: "55%",
                        borderRadius: "14px",
                        margin: "5px",
                      }}
                      src={item?.image2}
                    />
                  </>
                ) : (
                  <img
                    style={{
                      height: "35%",
                      width: "35%",
                      borderRadius: "14px",
                      margin: "5px",
                    }}
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
                    }
                  />
                )}
              </>
            ),
            description: (
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                }}
              />
            ),
            action: (
              <div style={{ display: "flex", flexDirection: "coloum" }}>
                <svg
                  onClick={() => onEdit(item)}
                  style={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                    marginRight: "20px",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path
                    fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                  />
                </svg>
                <svg
                  onClick={() => onDelete(item?._id)}
                  style={{
                    color: "red",
                    height: "20px",
                    cursor: "pointer",
                    width: "20px",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-trash3"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg>
              </div>
            ),
          };
        });
        setflokloreCollectionData(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onEdit = (item) => {
    console.log("itemddd", item?.extraNarrator)
    window.scrollTo(0, 0)
    setShow(false);
    setId(item?._id);
    setDesc(item?.description)
    item?.description && setFlokloreData({
      ...flokloreData,
      flokLoreId: item?.flokLoreId,
      name: item?.name,
      narrator: item?.narrator,
      age: item?.age,
      gender: item?.gender,
      village: item?.village,
      jobProfile: item?.jobProfile,
      Illustrator: item?.Illustrator,
      image1: item?.image1,
      subtitle: item?.subtitle,
      extraNarrator: item?.extraNarrator,
      flokLoreVolumeId: item?.flokLoreVolumeId,
      smallDescription: item?.smallDescription,
      readTime: item?.readTime
    });
    // console.log(item.extraNarrator);
    item?.extraNarrator && setFormValues(item?.extraNarrator)
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.DeleteFolkloreCollection(id)
          .then((res) => {
            if (res && res.status) {
              toast.success("Deleted Successfully");

              ViewFolkloreCollection();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      sortable: true,
      width: "80px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Tribe Name
        </div>
      ),
      selector: (row) => row.flokLoreName,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          FolkLore Volume
        </div>
      ),
      selector: (row) => row.volume,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Name
        </div>
      ),
      selector: (row) => row.name,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Sub-title
        </div>
      ),
      selector: (row) => row.subtitle,
    },

    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Narrator Name
    //     </div>
    //   ),
    //   selector: (row) => row.narrator,
    // },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       village Name
    //     </div>
    //   ),
    //   selector: (row) => row.village,
    // },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Image1
        </div>
      ),
      selector: (row) => row.image1,
    },

    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];

  const flokloreCategory = () => {
    HomeService.AllFolkloreData()
      .then((res) => {
        if (res && res?.status) {
          setflokloreCategoryData(res?.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleChange = (e) => {
    setFlokloreData({
      ...flokloreData,
      [e.target.name]: e.target.value,
    });
  };

  const imageHandler = async (e, val) => {
    if (val === "image1") {
      setImageLoader(true);
    } else {
      setImageLoader1(true);
    }

    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);

    let res = await HttpClientXml.fileUplode(
      "upload-Photocategory",
      "POST",
      data
    );
    if (res && res.status) {
      toast.success("Image uploaded successfully");
      if (val === "image1") {
        setFlokloreData({
          ...flokloreData,
          image1: res?.doc?.url,
        });
      } else {
        setFlokloreData({
          ...flokloreData,
          image2: res?.doc?.url,
        });
      }
      if (val === "image1") {
        setImageLoader(false);
      } else {
        setImageLoader1(false);
      }
    } else {
      if (val === "image1") {
        setImageLoader(false);
      } else {
        setImageLoader1(false);
      }
      toast.error("Failed to upload Image");
    }
  };

  // ll

  const AddCollCategory = () => {
    flokloreData['description'] = desc;
    flokloreData['extraNarrator'] = formValues;
    if (desc && flokloreData?.flokLoreId && flokloreData?.name) {
      console.log(flokloreData);
      HomeService.Addfolklorecollection(flokloreData)
        .then((res) => {
          if (res && res.status) {
            setFlokloreData(INITIAL);
            setDesc("");
            toast.success("Added successfully");
            ViewFolkloreCollection();
            let file = document.querySelector("#images");
            file.value = "";
            let files = document.querySelector("#imagess");
            files.value = "";
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("All Fields Are Required");
    }
  };

  const EditCollCategory = () => {
    flokloreData['description'] = desc;
    flokloreData['extraNarrator'] = formValues;
    if (desc && flokloreData?.flokLoreId && flokloreData?.name) {
      // console.log("FormVal:",formValues);
      console.log("Data:", flokloreData);
      HomeService.updatefolklorecollection(id, flokloreData)
        .then((res) => {
          console.log("Response Add", res);
          if (res && res.status) {
            setFlokloreData(INITIAL);
            setFormValues([])
            setShow(true);
            toast.success("Updated successfully");

            let file = document.querySelector("#images");
            file.value = "";
            let files = document.querySelector("#imagess");
            files.value = "";
            ViewFolkloreCollection();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("All Fields Are Required");
    }
  };


  ///Add Narrator


  const initialNarrator = {
    narrator: "",
    gender: "",
    age: "",
    jobProfile: "",
    village: "",
  };

  const [formValues, setFormValues] = useState([]);

  console.log("formValuesvv", formValues)

  const handleMultiChange = (i, e) => {
    console.log("e.target.value:", i, e.target.value);
    console.log(formValues);
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    // console.log(formValues);
  };

  const addFormFields = () => {
    // alert(formValues.length)
    if (formValues.length < 5000) {
      setFormValues([...formValues, initialNarrator]);
    } else {
      Swal("")
      Swal("Error", "Not more than 5000", "error");
    }
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    console.log(formValues);
  };


  useEffect(() => {
    gteFolkLoreVolume()
  }, [])

  useEffect(() => {
    flokloreCategory();
    ViewFolkloreCollection();
  }, [flokloreData]);

  ///Add Narrator//

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {show ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add Floklore
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Edit Floklore
              </div>
            )}


            <div>
              <label for="exampleInputEmail1">
                Tribe Name<span style={{ color: "red" }}>*</span> :
              </label>

              <select
                style={{ marginBottom: "21px" }}
                class="form-select"
                aria-label="select category"
                name="flokLoreId"
                value={flokloreData?.flokLoreId}
                onChange={(e) => { handleChange(e) }}
              >
                <option value={""}>Select a category name.......</option>
                {flokloreCategoryData.map((item) => {
                  return (
                    <option key={item?._id} id={item?._id} value={item?._id}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* FolkLore Volume */}
            <div>
              <label for="exampleInputEmail1">
                FolkLore Volume<span style={{ color: "red" }}>*</span> :
              </label>

              <select
                style={{ marginBottom: "21px" }}
                class="form-select"
                aria-label="select category"
                name="flokLoreVolumeId"
                value={flokloreData?.flokLoreVolumeId}
                onChange={(e) => { handleChange(e) }}
              >
                <option value={""}>Select a category name.......</option>
                {flokloreVolume?.map((item) => {
                  return (
                    <option key={item?._id} id={item?._id} value={item?._id}>
                      {item?.title}
                    </option>
                  );
                })}
              </select>
            </div>


            <div class="form-group">
              <label for="exampleInputEmail1">
                Name<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                name="name"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={flokloreData.name}
                onChange={(e) => { handleChange(e) }}
                placeholder="Enter name..."
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Sub Title<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                name="subtitle"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={flokloreData.subtitle}
                onChange={(e) => { handleChange(e) }}
                placeholder="Enter sub title..."
              />
            </div>


            <div class="form-group">
              <label for="exampleInputEmail1">
                Small Description<span style={{ color: "red" }}>*</span> :
              </label>
              <textarea
                type="text"
                class="form-control"
                name="smallDescription"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={flokloreData.smallDescription}
                onChange={(e) => { handleChange(e) }}
                placeholder="Enter sub title..."
              />
            </div>


            <label for="exampleInputPassword1">Description<span style={{ color: 'red' }}>*</span> :</label>


            <div style={{ marginBottom: '21px' }}>
              <CKEditor
                editor={ClassicEditor}
                data={desc}
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor);
                }}

                onChange={(event, editor) => {
                  console.log("eee,event", event, editor)
                  let data = editor.getData();

                  setDesc(data);
                }}
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Read Time :
              </label>
              <input
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="readTime"
                value={flokloreData.readTime}
                onChange={(e) => { handleChange(e) }}
                placeholder="Enter Read Time..."
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Illustrator<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                name="Illustrator"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={flokloreData?.Illustrator}
                onChange={(e) => handleChange(e)}
                placeholder="Enter  Illustrator..."
              />
            </div>

            <div class="mb-3">
              <label for="formFile" class="form-label">
                Upload Image 1<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                id="images"
                onChange={(e) => imageHandler(e, "image1")}
                class="form-control"
                accept="image/*"
                type="file"
              />
              {imageLoader ? (
                <>
                  <ImageLoader />{" "}
                </>
              ) : null}
              {flokloreData?.image1 && (
                <img
                  style={{
                    // height: "10%",
                    width: "10%",
                    marginTop: "12px",
                    borderRadius: "9px",
                  }}
                  src={flokloreData?.image1}
                />
              )}
            </div>

            <div className="row" data-aos="fade-up"
            // style={{ display: onEditing ? "none" : "block" }}
            >
              <div className="col-lg-12">

                <div style={{ border: "solid 1px #ced4da", padding: "1em", margin: "1em 0", borderRadius: "0.25rem" }}>

                  <label for="inputEmail4">
                    <strong>Narrator 1</strong>
                  </label>
                  <div class="form-group">
                    <label for="exampleInputEmail1">
                      Name<span style={{ color: "red" }}>*</span> :
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="narrator"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={flokloreData?.narrator}
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter narrator name..."
                    />
                  </div>



                  <label for="exampleInputEmail1">
                    Gender<span style={{ color: "red" }}>*</span> :
                  </label>
                  <div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={flokloreData?.gender === "male" ? true : false}
                        value="male"
                        onChange={(e) =>
                          setFlokloreData({ ...flokloreData, gender: e.target.value })
                        }
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Male
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        value="female"
                        checked={flokloreData?.gender === "female" ? true : false}
                        onChange={(e) =>
                          setFlokloreData({ ...flokloreData, gender: e.target.value })
                        }
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Female
                      </label>
                    </div>
                  </div>



                  <div class="form-group">
                    <label for="exampleInputEmail1">
                      Age<span style={{ color: "red" }}>*</span> :
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      name="age"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={flokloreData?.age}
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter narrator age..."
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">
                      Job Profile<span style={{ color: "red" }}>*</span> :
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="jobProfile"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={flokloreData?.jobProfile}
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter narrator jobProfile..."
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">
                      Village<span style={{ color: "red" }}>*</span> :
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="village"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={flokloreData?.village}
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter narrator village..."
                    />
                  </div>
                </div>

              </div>
            </div>




            {/* Multiple Narrators */}

            <div className="row" data-aos="fade-up"
            // style={{ display: onEditing ? "none" : "block" }}
            >
              <div className="col-lg-12">
                {/* <form> */}


                {formValues.map((element, index) => (

                  <div style={{ border: "solid 1px #ced4da", padding: "1em", margin: "1em 0", borderRadius: "0.25rem" }} className="_form-inline" key={index}>
                    <label for="inputEmail4">
                      <strong>Narrator {index + 2}</strong>
                    </label>
                    <div className="form-group mb-2 mt-1">
                      <label for="inputEmail4">
                        Name:
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="narrator"
                        placeholder={`Name Of narrator`}
                        value={element.narrator || ""}
                        onChange={(e) => handleMultiChange(index, e)}
                      />
                    </div>

                    <div className="form-group mb-2 mt-1">
                      <label for="inputEmail4">
                        Age:
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="age"
                        placeholder={`Age `}
                        value={element.age || ""}
                        onChange={(e) => handleMultiChange(index, e)}
                      />
                    </div>

                    <div className="form-group mb-2 mt-1">
                      <label for="inputEmail4">
                        Gender:
                      </label>
                      {/* <input
                        type="text"
                        class="form-control"
                        name="gender"
                        placeholder={`Gender`}
                        value={element.gender || ""}
                        onChange={(e) => handleMultiChange(index, e)}
                      /> */}

                      <select
                        style={{ marginBottom: "21px" }}
                        class="form-select"
                        aria-label="select category"
                        name="gender"
                        value={element.gender || ""}
                        onChange={(e) => handleMultiChange(index, e)}
                      >
                        <option value="">Select gender.......</option>

                        <option value="male">Male</option>
                        <option value="female">Female</option>

                      </select>

                      {/* <div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="gender"
                            id="flexRadioDefault1"
                            checked={element?.gender === "male" ? true : false}
                            value="male"
                            onChange={(e) => handleMultiChange(index, e)}
                          />
                          <label class="form-check-label" for="flexRadioDefault1">
                            Male
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="gender"
                            id="flexRadioDefault2"
                            value="female"
                            checked={element?.gender === "female" ? true : false}
                            onChange={(e) => handleMultiChange(index, e)}
                          />
                          <label class="form-check-label" for="flexRadioDefault2">
                            Female
                          </label>
                        </div>
                      </div> */}


                    </div>

                    <div className="form-group mb-2 mt-1">
                      <label for="inputEmail4">
                        Village:
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="village"
                        placeholder={`Village`}
                        value={element.village || ""}
                        onChange={(e) => handleMultiChange(index, e)}
                      />
                    </div>

                    <div className="form-group mb-2 mt-1">
                      <label for="inputEmail4">
                        Job Profile:
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="jobProfile"
                        placeholder={`Job Profile`}
                        value={element.jobProfile || ""}
                        onChange={(e) => handleMultiChange(index, e)}
                      />
                    </div>




                    {index >= 0 ? (
                      <button
                        type="button"
                        className="btn btn-sm btn-danger ml-1"
                        onClick={() => removeFormFields(index)}
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    ) : null}
                  </div>

                ))}


                {/* <div className="button-section mt-2">
                  <button
                    className="btn btn-sm btn-success"
                    type="button"
                    onClick={() => addFormFields()}
                  >
                    <i class="fas fa-plus"> Narrator</i>
                  </button>

                </div> */}
                {/* </form> */}
              </div>
            </div>

            {/* //Narrator */}

            <div className="button-section mb-2">
              <button
                className="btn btn-sm btn-success"
                type="button"
                onClick={() => addFormFields()}
              >
                <i class="fas fa-plus"> Narrator</i>
              </button>

            </div>

            {show ? (
              <button class="btn btn-primary" onClick={AddCollCategory}>
                Submit
              </button>
            ) : (
              <button class="btn btn-primary" onClick={EditCollCategory}>
                Update
              </button>
            )}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Manage Folklore Collection(s)
            </div>
            <DataTable
              columns={columns}
              data={flokloreCollectionData}
              pagination
            />
          </div>
        </div>
      </div>
    </>
  );
};

// export default Manage
