import React, { useState, useSyncExternalStore } from "react";
import HomeService from "../../Service/HomeService";
import HttpClientXml from "../../Utils/HttpClientXml";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import ImageLoader from "../../Loader/ImageLoader";
import SelectableInput from "../../Component/SelectableInput";
const AddPhotography = () => {
  const [hide, setHide] = useState(true);

  const [AllCatData, setAllCatData] = useState([]);
  const [catType, setCatType] = useState([]);

  const [themeData, setThemeData] = useState([]);
  const [themeId, setThemeId] = useState("");

  const [collectionCategoryId, setCategoryCollectionId] = useState("");
  const [catTypeId, setCatTypeId] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [img, setImg] = useState("");
  const [content , setContent] = useState("")
  const [imgLoader, setImgLoader] = useState(false);

  const [isFeatured, setIsFeatured] = useState(false);
  const [searchTag, setSearchTag] = useState([]);

  const [text, setText] = useState("");
  const [link, setLink] = useState("");
  useEffect(() => {
    fetchAllCollCatg();
    fetchCategoryType();
    fetchTheme();
  }, []);

  const handletheme = (e) => {
    setThemeId(e.target.value);
  };

  const fetchTheme = () => {
    HomeService.AllThematicCollection()
      .then((res) => {
        console.log("ResThematicCollsadsd", res.data);
        if (res && res?.status) {
          setThemeData(res?.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchAllCollCatg = () => {
    HomeService.AllCollectionCategory()
      .then((res) => {
        if (res && res?.status) {
          setAllCatData(res?.data);
        }
        console.log("RESCAT", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchCategoryType = () => {
    let arr = [];
    HomeService.getAllCollType()
      .then((res) => {
        if (res && res.status) {
          res?.data.forEach((element) => {
            arr.push(...element.categoryType);
          });
          setCatType(arr);
        }
        console.log("AAAAAAAAAAAAA", arr);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const HandlePhotography = () => {
    let data = {
      catId: collectionCategoryId,
      themeId: themeId,
      title: title,
      desc: desc,
      image: img,
      text: text,
      link: link,
      tag: searchTag,
      isFeatured: isFeatured,
      content:content
    };

    console.log("Data", data);

    if (collectionCategoryId && title && img && desc && content) {
      HomeService.AddPhotography(data)
        .then((res) => {
          console.log("Response Add Photography", res);
          if (res && res.status) {
            toast.success("Photography added successfully");
            setCategoryCollectionId("");
            setCatTypeId("");
            setTitle("");
            setDesc("");
            setImg("");
            setText("");
            setContent("");
            setLink("");
            setSearchTag([]);
            setIsFeatured(false);
            setThemeId("");
            let files = document.querySelector("#image");
            files.value = "";
          } else {
            toast.error("Photography name is already exist");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Mandatory fields are required");
    }
  };

  const handleCollCatName = (e) => {
    setCategoryCollectionId(e.target.value);
    setHide(false);
  };

  const handleCollCatType = (e) => {
    setCatTypeId(e.target.value);
  };

  const imageHandlers = async (e) => {
    toast.success("Please upload images with a height of 210 pixels and a width of 220 pixels.")
    setImgLoader(true);

    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);

    let res = await HttpClientXml.fileUplode(
      "upload-Photocategory",
      "POST",
      data
    );
    if (res && res.status) {
      toast.success("Image uploaded successfully");
      setImg(res?.doc?.url);
      setImgLoader(false);
    } else {
      toast.error("Failed to upload Image");
      setImgLoader(false);
    }
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Add Photography
            </div>
            <label for="exampleInputEmail1">
              {" "}
              Category<span style={{ color: "red" }}>*</span> :
            </label>
            <select
              style={{ marginBottom: "21px" }}
              class="form-select"
              aria-label="select category"
              value={collectionCategoryId}
              onChange={(e) => handleCollCatName(e)}
            >
              <option value={""}>Select a category.......</option>
              {AllCatData.map((item) => {
                return (
                  <option id={item?._id} value={item?._id}>
                    {item?.name}
                  </option>
                );
              })}
            </select>

            <label for="exampleInputEmail1"> Theme :</label>
            <select
              style={{ marginBottom: "21px" }}
              class="form-select"
              aria-label="select category"
              value={themeId}
              onChange={(e) => handletheme(e)}
            >
              <option value={""}>Select a theme.......</option>
              {themeData.map((item) => {
                return (
                  <option id={item?._id} value={item?._id}>
                    {item?.name}
                  </option>
                );
              })}
            </select>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Title<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter title..."
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Source Text :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Enter text..."
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Source Link :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                placeholder="Enter link..."
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Description<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                placeholder="Enter description..."
              />
            </div>

            <div className="form-group">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue=""
                  id="featured"
                  checked={isFeatured}
                  onChange={(val) => setIsFeatured(val.target.checked)}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Featured
                </label>
              </div>
            </div>
            <SelectableInput
              title="Search tag :"
              value={searchTag}
              onChange={(val) => setSearchTag(val)}
              className="form-group"
              placeholder="add search tag.."
            />

            <div class="mb-3">
              <label for="formFile" class="form-label">
                Upload Image<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                id="image"
                onChange={imageHandlers}
                class="form-control"
                type="file"
                accept="image/*"
              />
              {imgLoader ? <ImageLoader /> : null}
              {img && (
                <img
                  style={{
                    height: "10%",
                    width: "10%",
                    marginTop: "12px",
                    borderRadius: "12px",
                  }}
                  src={img}
                />
              )}
            </div>

            <div class="form-group">
          <label for="exampleInputEmail1">
            Content<span style={{ color: "red" }}>*</span> :
          </label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Enter content"
          />
        </div>

        <div class="form-group">
          <label for="exampleInputEmail1">
            Content<span style={{ color: "red" }}>*</span> :
          </label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Enter content"
          />
        </div>

            <button class="btn btn-primary" onClick={HandlePhotography}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPhotography;
