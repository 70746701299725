import React from "react";
import { useState, useEffect } from "react";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import { flushSync } from "react-dom";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";

const ManagePhotoGallery = () => {
  const [AllCatData, setAllCatData] = useState([]);
  const [allPhotoCat, setAllPhotoCat] = useState([]);

  const [collectionCategoryId, setCategoryCollectionId] = useState("");
  const [photoCategoryId, setPhotoCategoryId] = useState("");

  const [selectedData, setSelectedData] = useState([]);

  const [hide, setHide] = useState(true);
  const [hide1, setHide1] = useState(true);

  const [index, setIndex] = useState(-1);

  const [allPhotoGallery, setAllPhotoGallery] = useState([]);
  
  const [photoCategoryName, setPhotoCategoryName] = useState("");

  const [check, setCheck] = useState(false);

  const [editId, setEditId] = useState("");

   const [themeData , setThemeData] = useState([]);
    const [themeId , setThemeId] = useState('')
  

  const navigate = useNavigate();

  useEffect(() => {
    fetchAllCollCatg();
    fetchTheme();
  }, []);

        const fetchTheme = () => {
        HomeService.AllThematicCollection()
        .then((res) => {
            console.log("ResThematicCollsadsd" , res.data);
          if (res && res?.status) {
            setThemeData(res?.data);
          }
      })
      .catch((err) => {
        
        console.log("err", err);
      });
    }

  // console.log("CATE");

  const fetchAllPhotoGallery = (id) => {

    console.log("DATA", id);
    HomeService.fetchAllPhotoGallerys(id)
      .then((res) => {
        console.log("RESCATPHOTOGAL", res.data );
        if (res && res?.status) {
          let arrgallery = res?.data[0]?.gallery
          if(arrgallery.length !== 0){
            setAllPhotoGallery(res?.data[0]?.gallery[0]?.photos);
          }else{
            setAllPhotoGallery([]);
          }
          
          console.log("ALLPHOTOS" , res?.data[0].gallery[0].photos);
          setEditId(res?.data[0]?.gallery[0]?._id)
        }
        // console.log("RESCAT", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };



  const fetchAllCollCatg = () => {
    HomeService.AllCollectionCategory()
      .then((res) => {
        if (res && res?.status) {
          setAllCatData(res?.data);
        }
        console.log("RESCAT", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // const fetchAllPhotography = (id) => {
  //   console.log("ID", id);
  //   HomeService.getAllPhotography(id)
  //     .then((res) => {
  //       console.log("allphots",res);
  //       setAllPhotoCat(res?.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const handleDelete = (id) => {
    let data = {
      isDeleted: true,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.DeletePhotoCategory(id, data)
          .then((res) => {
            console.log("RESPONSEDELETECOLL", res);
            if (res && res.status) {
              toast.success("Delete Successfully");
              // fetchAllColl();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handleCollCatName = (e) => {
    setCategoryCollectionId(e.target.value);
    console.log("categoryId", e.target.value);

    e.target.value === ""
      ? setCategoryCollectionId("")
      : fetchAllPhotography(e.target.value);
    setHide(false);
  };

  const HandlePhotogallerys = (e) => {
    setPhotoCategoryId(e.target.value);
      if(e.target.value !== "") {
        fetchAllPhotoGallery(e.target.value);
      }
    setHide1(false);
  };

  const EditHandler = (allPhoto , photoId , CollID) => {
    // console.log("edit",allPhotoGallery);
    // return
    // navigate("/edit-photo-gallery", {
    //   state: {
    //     collectionCategoryId: collectionCategoryId,
    //     photoCategoryId: photoCategoryId,
    //     photos: allPhotoGallery,
    //   },
    // });

    let data = {
      photoId: editId,
      CollID: CollID,
      photos: allPhoto
    }

    console.log("editdata",data);
    // return

    navigate('/edit-photo-gallery' , {state: data});

    // let data = {
    //   id : photoCategoryId,
    //   item : SelectedData,
    //   catId : collectionCategoryId,
    //   index:index
    // }

    // console.log("LENGTH", selectedData.length);

    //   if(SelectedData.length !== 0){
    //     navigate('/edit-photo-gallery',{state:data})
    //   }else{
    //     toast.error("Please Select Checkbox First ")
    //   }

    // if(index != -1){
    //   navigate('/edit-photo-gallery',{state:data})
    // }else{
    //   toast.error("Please Select Checkbox First ")
    // }
  };

  
      const handletheme = (e) => {
        
        setThemeId(e.target.data)
        // fetchAllPhotography(e.target.value);
      }


      
        const fetchAllPhotography = (id) => {
        console.log("LLLLLLLLLLLLL");


    
    HomeService.getAllPhotography(id)
      .then((res) => {
        console.log("responseget ALl PhotoGraphy" , res);
        if (res && res?.status) {
        setAllPhotoCat(res?.data)
          // setCategoryPhotography(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        // setLoader(false)
        console.log("err", err);
      });
  };

  let arr = [];
  const HandleCheckbox = (event, items, index) => {
    // if(event.target.checked  === true){
    //   arr.push(items)
    // }else{
    //   console.log("ARR" , arr  , items?._id);
    //   // setEditId(item?._id)
    //   arr.forEach((item , index) => {
    //     if( items?._id === item?._id ){
    //       console.log("IIIIIIIIIIIII", index);
    //       arr.splice(index , 1)
    //     }
    //   })
    //   // arr.pop()
    // }
    // setSelectedData(arr);
    // console.log("EVEVB" , event , arr);

    if (event.target.checked === true) {
      setIndex(index);
    }
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              MANAGE PHOTO GALLERY
            </div>
            <label for="exampleInputEmail1"> Category<span style={{ color:'red'}}>*</span> :</label>
            <select
              style={{ marginBottom: "21px" }}
              className="form-select"
              aria-label="select category"
              value={collectionCategoryId}
              onChange={(e) => handleCollCatName(e)}
              // onChange={HandlePhotogallerys}
            >
              <option value={""}>Select a category.......</option>
              {AllCatData.map((item) => {
                return (
                  <option id={item?._id} value={item?._id}>
                    {item?.name}
                  </option>
                );
              })}
            </select>

                    {/* <label for="exampleInputEmail1"> Theme :<span style={{ color:'red'}}>*</span> :</label>
          <select
          style={{ marginBottom: "21px" }}
          class="form-select"
          aria-label="select category"
          value={themeId}
          onChange={(e) => handletheme(e)}
        >
          <option value={""}>Select a theme.......</option>
          {themeData.map((item) => {
            return (
              <option id={item?._id} value={item?._id}>
                {item?.name}
              </option>
            );
          })}
        </select> */}

            <label for="exampleInputEmail1"> Photocategory<span style={{ color:'red'}}>*</span> :</label>
            <select
              style={{ marginBottom: "21px" }}
              className="form-select"
              aria-label="select category"
              value={photoCategoryId}
              onChange={(e) => HandlePhotogallerys(e)}
              disabled={hide}
            >
              <option value={""}>Select a Photocategory.......</option>
              {allPhotoCat.map((item) => {
                return (
                  <option id={item?._id} value={item?._id}>
                    {item?.title}
                  </option>
                );
              })}
            </select>
            {/* {
              photoCategoryId ? <DataTable  columns={columns} data={allPhotoGallery}/> : null
            } */}
  
            {photoCategoryId ? (
              <div className=" d-flex" style={{ overflowX:'scroll' }}>
                {allPhotoGallery.map((item) => {
                  // return item?.photos.map((item, index) => {
                    return (
                      <div className="card-group">
                        <div className="card-body">
                          {
                            item?.image &&      <img
                            style={{ objectFit: "contain", aspectRatio: "4/3" ,  width: "200px",
                            height: "300px" }}
                            className="card-img-top"
                            src={item?.image}
                          />
                          }
                     

                          <h3
                            style={{ textAlign: "center" }}
                            className="card-title"
                          >
                            {item?.name}
                          </h3>
                        </div>
                      </div>
                    );
                  // });
                })}
              </div>
            ) : null}
          </div>

          {!hide1 && allPhotoGallery.length !== 0 ? (
            <div>
              <button
                onClick={() => EditHandler(allPhotoGallery ,photoCategoryId ,collectionCategoryId )}
                style={{ margin: "9px 9px" }}
                type="button"
                className="btn btn-primary"
              >
                Edit
              </button>

            </div>
          ) : null}

          { photoCategoryId && allPhotoGallery.length === 0 ? <div>There are no records to display</div> : null }
        </div>
      </div>
    </>
  );
};

export default ManagePhotoGallery;

// <h1 >
//         GeeksforGeeks
//     </h1>

//     <div className="container">
//         <div className="card-group">

//             <div className="row">
//                 <div className="card col-md-4">
//                     <img className="card-img-top" src=
// "https://media.geeksforgeeks.org/wp-content/cdn-uploads/20190710102234/download3.png"/>

//                     <div className="card-body">
//                         <h3 className="card-title">Compare</h3>
//                         <p className="card-text">JavaScript | Python</p>
//                     </div>
//                 </div>

//             </div>
//         </div>
//     </div>
