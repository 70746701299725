import React, { useEffect, useState } from 'react'
import HttpClientXml from '../../Utils/HttpClientXml'
import HomeService from '../../Service/HomeService'
import DataTable from 'react-data-table-component'
import toast from 'react-hot-toast'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const EditDetails = () => {
    const [content ,setContent] = useState("");
    const [ThematicType , setThematicType] = useState([]);
    const [image , setImage] = useState('');
    const [imageLoader , setImageLoader] = useState(false);
    const [description , setDescription] = useState('');

    const [ThemeData , setThemeData] = useState([])

    const [ids , setId] = useState('')

    useEffect(()=> {
        fetchThematic()
        
    },[])


    const onEdit = (item) => {
        setContent(item?.content)
        setDescription(item?.summary);
        setImage(item?.img);
        setId(item?._id);
    }






    const fetchThematic = () => {
        HomeService.ViewAllThematic()
        .then((res)=> {
          let arr = [];
            console.log("AllThematic",res);
            if(res && res?.status){
              
              if(res?.data){
                
                res?.data.map((item) => {
                  
                  if(item?.themeType === 'startup'){
                      arr.push(item);
                  }
                })
                
              }
            };

            console.log("Arr" , arr);
            if(res && res?.status){
               
                let arrs = arr?.map((item, index) => {
                    return{
                        sl:<div >{index+1}</div>,
                        Description:<div>{item?.summary}</div>,
                        type:item?.themeType,
                        image: (
                            <>
                            {
                              (item?.image !== '') ? <img style={{ height: '35%', width: '35%' , borderRadius:'9px' , margin:"5px" }} src={item?.img} /> :
                              <img style={{ width: '42%' , borderRadius:'9px' , margin:"5px" }} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} />
                            }
                            </>
                        ),
                        action:(
                            <div style={{ display: "flex", flexDirection: "coloum" }}>
                            <svg
                              onClick={() => onEdit(item)}
                              style={{
                                height: "20px",
                                width: "20px",
                                cursor: "pointer",
                                marginRight: "20px",
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path
                                fill-rule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                              />
                            </svg>
                            
                          </div>
                        )
                    }
                })
                setThematicType(arrs);
            }else{
                toast.error(res?.message)
            }
            
        })
        .catch((err) => {
            console.log("err", err);
          });
    }


    const columns = [
        {
          name: <div style={{ fontSize: "14px", maxWidth:'18px',color:'#495057', fontWeight: "bolder" }}>SL</div>,
          selector: (row) => row.sl,
          sortable: true,
          width:"80px",
        },
        {
          name: <div style={{ fontSize: "14px",color:'#495057', fontWeight: "bolder" }}>Type</div>,
          selector: (row) => row.type,
        },
        {
          name: (
            <div style={{ fontSize: "14px", color:'#495057',  fontWeight: "bolder" }}>
            Description
            </div>
          ),
          selector: (row) => row.Description,
        },
        {
          name: <div style={{ fontSize:'14px' ,color:'#495057', fontWeight:'bolder'}}>Image</div>,
          selector: row => row.image,
        },
        {
            name: (
              <div
                style={{ fontSize: "14px", color:'#495057', marginLeft: "15px", fontWeight: "bolder" }}
              >
                Action
              </div>
            ),
            selector: (row) => row.action,
          },
      ];


  const imageHandler = async (e) => {
    toast.success("Please upload images with a height of 260 pixels and a width of 300 pixels.")
    setImageLoader(true)
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);



    let res = await HttpClientXml.fileUplode("upload-Photocategory" , "POST" , data);
    if(res && res.status){
      toast.success("Image uploaded successfully");

      setImage(res?.doc?.url);
      setImageLoader(false)
    }else{
        setImageLoader(false)
      toast.error("Failed to upload Image")
    }
  };

  const EditThematic= () => {
    let data = {
       
        summary:description,
        img:image,
        content:content
    }

    if(description && image && content){
      HomeService.UpdateThematics(ids , data)
      .then((res) => {
          console.log("Response Edit" , res);
          if(res && res.status){
              toast.success("Updated Successfully");
              setDescription("");
              let file = document.querySelector("#images");
              file.value = "";
              setContent("")
              setImage("");
              fetchThematic()
          }else{
            toast.error(res?.message);
          }
      })
      .catch((err) => {
          console.log(err);
      })
    }else{
      toast.error("All Fields Are Required")
    }
  }

  const HandleCrossClick = () => {
    setImage("");
    let file = document.querySelector("#images");
    file.value = "";
  }


  return (
   <>
      <div component="div" className="TabsAnimation appear-done enter-done">
      <div className="main-card mb-3 card">
        <div className="card-body">
          {/* {loader ? (
            <div style={{ textAlign: "center" }}>
              {" "}
              <Loader />{" "}
            </div>
          ) : null} */}
          <div
            style={{
              textAlign: "center",
              fontSize: "20px",
              color: "#868e96",
              margin: "35px",
            }}
            className="card-title"
          >
            Edit Details In Startups in Nagaland
          </div>

          <div class="form-group">
              <label for="exampleInputPassword1">Description<span style={{ color:'red'}}>*</span> :</label>
              {/* <input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Enter description..."
              /> */}
              <div style={{ marginBottom: '21px' }}>
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor);
                }}

                onChange={(event, editor) => {
                  console.log("eee,event", event, editor)
                  let data = editor.getData();

                  setDescription(data);
                }}
              />
            </div>

            </div>

            





            <div class="form-group">
          <label for="exampleInputEmail1">
            Content<span style={{ color: "red" }}>*</span> :
          </label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Enter content"
          />
        </div>

            <div class="mb-3">
              <label for="formFile" class="form-label">
                Upload Image<span style={{ color:'red'}}>*</span> :
              </label>
              <input
                id="images"
                onChange={imageHandler}
                class="form-control"
                accept="image/*"
                type="file"
              />
              {/* {imageLoader ? <><ImageLoader/> </>  :null} */}
              { image && <>
                <img style={{ height: "10%", width: "10%" , marginTop:'12px' , borderRadius:'9px' }} src={image} />
                <button onClick={() => HandleCrossClick()} style={{ color: 'red'}} type="button" class="btn-close" aria-label="Close"></button>
              </>
              }
            </div>

            <button  class="btn btn-primary" onClick={EditThematic}>
              Submit
            </button>


            <div
            style={{
              textAlign: "center",
              fontSize: "20px",
              color: "#868e96",
              margin: "35px",
            }}
            className="card-title"
          >
            View Thematic
          </div>

          <DataTable columns={columns} data={ThematicType} pagination />

        </div>
      </div>
    </div>
   </>
  )
}

export default EditDetails