import React, { useState, useSyncExternalStore } from "react";

import Books from "../CollectionType/Books";
import Documents from "../CollectionType/Documents";
import Audios from "../CollectionType/Audios";
import Videos from "../CollectionType/Videos";
import PapersAndResearch from "../CollectionType/PapersAndJournals";
import Reports from "../CollectionType/Reports";

const AddCollection = () => {
 
  const [activeTab, setActiveTab] = useState(0);
  const tabCount = 6;
  const handleNext = () => {
    setActiveTab((prevTab) => (prevTab === tabCount - 1 ? 0 : prevTab + 1));
  };
  const handleSkip = () => {
    setActiveTab((prevTab) => (prevTab === tabCount - 1 ? 0 : prevTab + 1));
  };

  const handleClicks = (num) => {
    setActiveTab(num);
  }




  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
              <div  className="collectionList " style={{ height:'47px' , backgroundColor: '#868e96',
                        padding: '12px' , borderRadius:'8px'}}>
                    <span onClick={() => handleClicks(0)}  style={{color:activeTab===0?"blue":"#EBEBE4" ,fontWeight:'bold'}}>Books</span>
                    <span onClick={() => handleClicks(1)} style={{color:(activeTab===1?"blue":"#EBEBE4"), fontWeight:'bold'}}>Documents</span>
                    <span onClick={() => handleClicks(2)}  style={{color:activeTab===2?"blue":"#EBEBE4" ,fontWeight:'bold'}}>Audios</span>
                    <span onClick={() => handleClicks(3)} style={{color:activeTab===3?"blue":"#EBEBE4" , fontWeight:'bold'}}>Videos</span>
                    <span onClick={() => handleClicks(4)} style={{color:activeTab===4?"blue":"#EBEBE4" ,fontWeight:'bold'}}>Paper & Journals</span>
                    <span  onClick={() => handleClicks(5)} style={{color:activeTab===5?"blue":"#EBEBE4" ,fontWeight:'bold'}}>Reports</span>
                    </div>
          { activeTab===0 &&      <Books/> }
          { activeTab===1 &&  <Documents/> }
          { activeTab===2 &&  <Audios/> }
          { activeTab===3 &&  <Videos/> }
          { activeTab===4 &&  <PapersAndResearch/> }
          { activeTab===5 &&  <Reports/> }

     
        </div>
        
      </div>
      
      <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button  onClick={handleSkip}  class="btn btn-primary">
                Skip
              </button>
            </div>

    </>
  )
}

export default AddCollection