import React, { useState } from "react";
import HomeService from '../../Service/HomeService';
import HttpClientXml from "../../Utils/HttpClientXml";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

const AddCollectionType = () => {

    const [name , setName] = useState('');
    const [description , setDescription] = useState('');
    const [image , setImage] = useState('');
    const [collectionCategoryId , setCategoryCollectionId] = useState('');

    const [AllCatData , setAllCatData] = useState([]);



    useEffect(() => {
        fetchAllCollCatg();
      }, []);

      const fetchAllCollCatg = () => {
        HomeService.AllCollectionCategory()
          .then((res) => {
            if (res && res?.status) {
                setAllCatData(res?.data)
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      };


//   const imageHandler = async (e) => {
//     let file = e.target.files[0];
//     let data = new FormData();
//     data.append("doc", file);

//     // let res = await HttpClientXml.fileUplode(
//     //   "upload-collection-document",
//     //   'POST',
//     //   file
//     // );

//     let res = await HttpClientXml.fileUplode("upload-collection-document" , "POST" , data)
//     console.log("ImageRes", res);
//   };

const AddCollType = () => {

    let data = {
        collectionCategoryId: collectionCategoryId,
        name: name
    }

    console.log("Data" , data);
    if(name && collectionCategoryId){
      HomeService.AddCollType(data)
      .then((res) => {
          console.log("Response Add" , res);
          if(res && res.status){
              toast.success(res.message);
              setName("");
              setCategoryCollectionId("");
              
          }
      })
      .catch((err) => {
          console.log(err);
      })
    }else{
      toast.error("All Fields Are Required")
    }


}

const handleCollCat = (e) => {
    setCategoryCollectionId(e.target.value);
}

  return (
    <>
        <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div style={{ textAlign: "center" , fontSize: "20px" , color:'#868e96',  margin:'35px'}} className="card-title">
              Add Collection Type
            </div>
            <label for="exampleInputEmail1">Collection Category :</label>
            <select
          style={{ marginBottom: "21px" }}
          class="form-select"
          aria-label="select category"
          value={collectionCategoryId}
          onChange={(e) => handleCollCat(e)}
        >
          <option value={""}>Select a collection category.......</option>
          {AllCatData.map((item) => {
            return (
              <option id={item?._id} value={item?._id}>
                {item?.name}
              </option>
            );
          })}
        </select>
            <div class="form-group">
              <label for="exampleInputEmail1">Collection Type :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter collection type..."
              />
            </div>

            <button  class="btn btn-primary" onClick={AddCollType}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddCollectionType