import React, { useState, useSyncExternalStore } from "react";
import HomeService from '../../Service/HomeService';
import HttpClientXml from "../../Utils/HttpClientXml";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import SelectableInput from "../../Component/SelectableInput";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-datepicker";
import moment from "moment";

const Videos = () => {
    const [hide, setHide] = useState(false);
    const [hide1 , setHide1] = useState(false)

    const [image, setImage] = useState("");
    const [img, setImg] = useState("");
    const [imgs , setImgs] = useState('');
    const [collectionCategoryId, setCategoryCollectionId] = useState("");


    const [dateType, setDateType] = useState("");

    const [publishDate, setpublishDate] = useState("");
  
    const [themeCollection, setThemeCollection] = useState("");
    const [catTypeId, setCatTypeId] = useState("");
    console.log("ddddddddddd", catTypeId);
  
    const [AllCatData, setAllCatData] = useState([]);
    const [catType, setCatType] = useState([]);
    console.log("catTypettttttt", catType);

  const [date , setDate] = useState('')

  
    const [title, setTitle] = useState("");
    const [summery, setSummery] = useState("");
    const [author, setAuthor] = useState("");
    const [desc, setDesc] = useState("");
    const [partner, setPartner] = useState("");
    const [contribution_institution, setContribution_institution] = useState("");
    const [subject, setSubject] = useState("");
    const [url, setUrl] = useState("");
    const [license, setLicense] = useState("");
  
    const [allthemeCollection, setAllThemeCollection] = useState([]);

    const [isFeatured, setIsFeatured] = useState(false);
    const [searchTag, setSearchTag] = useState([]);
    const [collectionUrl , setCollectionUrl] = useState("");

  
    useEffect(() => {
      fetchAllCollCatg();
      fetchCategoryType();
      fetchThemeCollection();
    }, []);


    const renderYearContent = (year) => {
      const tooltipText = `Tooltip for year: ${year}`;
      return <span title={tooltipText}>{year}</span>;
    };
  
    const fetchAllCollCatg = () => {
      HomeService.AllCollectionCategory()
        .then((res) => {
          if (res && res?.status) {
            setAllCatData(res?.data);
          }
          console.log("RESCAT", res.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    };
  
    const fetchCategoryType = () => {
      let arr = [];
      HomeService.getAllCollType()
        .then((res) => {
          if (res && res.status) {
            res?.data.forEach((element) => {
              arr.push(...element.categoryType);
            });
            setCatType(arr);
          }
          console.log("AAAAAAAAAAAAA", arr);
        })
        .catch((err) => {
          console.log("err", err);
        });
    };
  
    const fetchThemeCollection = () => {
      HomeService.AllThematicCollection()
        .then((res) => {
          if (res && res?.status) {
            setAllThemeCollection(res?.data);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    };
  
  
  
    const AddCollection = () => {
      let data = {
        catId: collectionCategoryId,
        type: "Videos",
        themeId: themeCollection,
        title: title,
        uploadCollection: image,
        image: img,
        collectionImage:imgs,
        link:collectionUrl,
        summery: (summery.replace("<p>","").replace("</p>","")),
        author: author,
        desc: desc,
        partner: partner,
        contribution_institution: contribution_institution,
        subject: subject,
        publishDate:publishDate,
        url: url,
        license: license,
        searchTag:searchTag,
      isFeatured
  
      };
      console.log("Data", data);
      if (
        collectionCategoryId &&
        // themeCollection &&
        title &&
        // image &&
        // img &&
        // imgs &&
        summery 
        // author &&
        // isFeatured &&
        // searchTag.length !== 0
        // desc &&
        // partner &&
        // contribution_institution &&
        // subject &&
        // url &&
        // license
      ) {
        if(!searchTag.length){
          toast.error("Press ‘ ENTER ’ after writing each tag ")
          return 
        }

        if(new Date(publishDate) > new Date()){
          toast.error('Publish date must not be Future Date……It must be either Past date or Current Date');
              return
          }

        HomeService.AddCollection(data)
          .then((res) => {
            console.log("Response Add Collection", res);
            if (res && res.status) {
                toast.success("Collection added successfully");
              setCategoryCollectionId("");
              setThemeCollection("");
              setTitle("");
              setImage("");
              setImg("");
              setSummery("");
              setAuthor("");
              setDesc("");
              setPartner("");
              setCollectionUrl("");
              setDate('');
              setContribution_institution("");
              setpublishDate("")
              setSubject("");
              setUrl("");
              setLicense("");
              let file = document.querySelector("#images");
              file.value = "";
              let files = document.querySelector("#image");
              files.value = "";
                      let filess = document.querySelector("#img");
            filess.value = "";
            setImgs("")

            setHide1(false);
            setHide(false)

            setIsFeatured(false);
            setSearchTag([])

            }else{
            toast.error("Collection Title Already Exists")
          }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        toast.error("Mandatory fields are required");
      }
    };
  
    const handleCollCatName = (e) => {
      setCategoryCollectionId(e.target.value);
      // setHide(false);
    };
  
    const handleCollCatType = (e) => {
      setCatTypeId(e.target.value);
    };
  
    const imageHandler = async (e) => {
      setHide(true);
      let file = e.target.files[0];

   
      let data = new FormData();
      data.append("video", file);
  
      // if((file?.type) === 'application/pdf'){
        let res = await HttpClientXml.fileUplode(
          "upload-collection-video",
          "POST",
          data
        );
        if (res && res.status) {
          toast.success("Video File Uploaded Successfully");
          setImage(res?.data);
        } else {
          toast.error("Failed to upload Image");
        }
      // }else{
      // toast.error("Only Upload Pdf file")
      // let files = document.querySelector("#images");
      // files.value = "";
    // }
      
    };

    
  
    const imageHandlers = async (e) => {
      let file = e.target.files[0];
      let data = new FormData();
      data.append("image", file);
  
      let res = await HttpClientXml.fileUplode(
        "upload-Photocategory",
        "POST",
        data
      );
      if (res && res.status) {
        toast.success(" Uploaded Successfully");
        setImg(res?.doc?.url);
      } else {
        toast.error("Failed to upload Image");
      }
    };
  
    const handlethemeCollection = (e) => {
      setThemeCollection(e.target.value);
    };
  
    const HandleCrossClick = () => {
      setHide(false)
      setImage("");
      let file = document.querySelector("#images");
      file.value = "";
    }

    const collectionUrls = (e) => {
      e.target.value ?   setHide1(true) : setHide1(false);
      setCollectionUrl(e.target.value);
      
    }

    const imageHandlerss = async (e) => {
      let file = e.target.files[0];
      let data = new FormData();
      data.append("image", file);
  
      let res = await HttpClientXml.fileUplode(
        "upload-Photocategory",
        "POST",
        data
      );
      if (res && res.status) {
        toast.success(" Uploaded Successfully");
        setImgs(res?.doc?.url);
      } else {
        toast.error("Failed to upload Image");
      }
    };
    const HandleCrossClickss = () => {
      setImgs("");
      let file = document.querySelector("#img");
      file.value = "";
    }
  
  
    const HandleCrossClicks = () => {
      setImg("");
      let file = document.querySelector("#image");
      file.value = "";
    }

  return (
    <>
              <div className="card-body">
        <div
          style={{
            textAlign: "center",
            fontSize: "20px",
            color: "#868e96",
            margin: "35px",
          }}
          className="card-title"
        >
          Add Videos
        </div>

        <label for="exampleInputEmail1">Collection Category<span style={{ color:'red'}}>*</span> :</label>

        <select
          style={{ marginBottom: "21px" }}
          class="form-select"
          aria-label="select category"
          value={collectionCategoryId}
          onChange={(e) => handleCollCatName(e)}
        >
          <option value={""}>Select a collection category.......</option>
          {AllCatData.map((item) => {
            return (
              <option id={item?._id} value={item?._id}>
                {item?.name}
              </option>
            );
          })}
        </select>

        <label for="exampleInputEmail1">Theme :</label>
       
        <select
          style={{ marginBottom: "21px" }}
          class="form-select"
          aria-label="select theme"
          value={themeCollection}
          onChange={(e) => handlethemeCollection(e)}
          // disabled={hide}
        >
          <option value={""}>Select a theme.......</option>
          {allthemeCollection.map((item) => {
            return (
              <option id={item?._id} value={item?._id}>
                {item?.name}
              </option>
            );
          })}
        </select>

        <div class="form-group">
          <label for="exampleInputEmail1">Title<span style={{ color:'red'}}>*</span> :</label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter title"
          />
        </div>

        <div class="form-group">
          <label for="exampleInputEmail1">
            Collection URL  :
          </label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={collectionUrl}
            onChange={(e) => collectionUrls(e)}
            placeholder="Enter Collection URL.."
            disabled={hide}
          />
        </div>

        <div class="mb-3">
          <label for="formFile" class="form-label">
            Upload Collection :
          </label>
          <input
            id="images"
            onChange={imageHandler}
            class="form-control"
            accept="video/*"
            type="file"
            disabled={hide1}
          />
          {image && 
          <>

            <svg style={{ height:'15%' , width:'15%'}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
</svg>

            <button onClick={() => HandleCrossClick()} style={{ color: 'red'}} type="button" class="btn-close" aria-label="Close"></button>
          </>
            
          }
          </div>



          
          <div class="mb-3">
            <label for="formFile" class="form-label">
              Thumbnail Image :
            </label>
            <input
              id="image"
              onChange={imageHandlers}
              class="form-control"
              type="file"
              accept="image/*"
            />
                        {img && <>
                            <img
                style={{
                  height: "10%",
                  width: "10%",
                  marginTop: "12px",
                  borderRadius: "12px",
                }}
                src={img}
              />
                <button onClick={() => HandleCrossClicks()} style={{ color: 'red'}} type="button" class="btn-close" aria-label="Close"></button>

                        </>
                        

            }
            </div>

            <div class="mb-3">
            <label for="formFile" class="form-label">
            Collection Image :
            </label>
            <input
              id="img"
              onChange={imageHandlerss}
              class="form-control"
              type="file"
              accept="image/*"
            />
                        {imgs && <>
                            <img
                style={{
                  height: "10%",
                  width: "10%",
                  marginTop: "12px",
                  borderRadius: "12px",
                }}
                src={imgs}
              />
                <button onClick={() => HandleCrossClickss()} style={{ color: 'red'}} type="button" class="btn-close" aria-label="Close"></button>
                </>
            }
            </div>
            {/* <div class="form-group">
              <label for="exampleInputEmail1">Summary<span style={{ color:'red'}}>*</span> :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={summery}
                onChange={(e) => setSummery(e.target.value)}
                placeholder="Enter summary"
              />
            </div> */}

<label for="exampleInputEmail1">
            Summary<span style={{ color: "red" }}>*</span> :
          </label>
          <div style={{ marginBottom: '21px'}}>
                <CKEditor
                    editor={ ClassicEditor }
                    data={summery}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }

                    onChange={(event, editor) => {
                      // let arr = [];
                      // let data = editor.getData();
                      // let splits = data.split(/(\s+)/);
                      // let words = splits.filter((x) => x.trim().length>0);
                            // var count = words.length;
                      // if(words.length < 50){
                        setSummery(editor.getData());
                      // }else{
                        // toast.error("Summary length should be less than 50 character");
                      // }
                    }}
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                    
                />
                </div>

            <div class="form-group">
              <label for="exampleInputEmail1">Author :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                placeholder="Enter author"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Description :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                placeholder="Enter description"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Partner :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={partner}
                onChange={(e) => setPartner(e.target.value)}
                placeholder="Enter partner"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Contributing Institution :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={contribution_institution}
                onChange={(e) => setContribution_institution(e.target.value)}
                placeholder="Enter Contributing Institution"
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">Subject :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Enter subject"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">URL :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter URL"
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">License / Right :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={license}
                onChange={(e) => setLicense(e.target.value)}
                placeholder="Enter license"
              />
            </div>

            <div class="form-group">
          <label for="exampleInputEmail1">
            Publish Date Type :
          </label>

          <select
            style={{ marginBottom: "21px" }}
            class="form-select"
            aria-label="select category"
            value={dateType}
            onChange={(e) => {
              setpublishDate("");
              setDateType(e.target.value);
            }}
          >
            <option value={""}>Select a publish date type.......</option>
            {["year", "month", "date"].map((item) => {
              return <option value={item}>{item}</option>;
            })}
          </select>
        </div>

        {dateType === "year" && (
          <div style={{ marginBottom: "12px" }}>
            <label for="exampleInputEmail1"> Select Year :</label>
            <DatePicker
              className="form-control"
              // style={{ width : "130px"}}
              selected={ new Date(publishDate,0) }
         
              renderYearContent={renderYearContent}
              showYearPicker
              onChange={(year) => {
                console.log("ssssssss", moment(year).year());
               
                setpublishDate(moment(year).year());
              }}
              dateFormat="yyyy"
            />
          </div>
        )}

        {dateType === "month" && (
          <div style={{ marginBottom: "12px" }}>
                 <label for="exampleInputEmail1">Select Month & Year :</label>
            <input
              className="form-control"
              type="month"
              value={publishDate}
              onChange={(e) => {
                setpublishDate(e.target.value);
              }}
            />
          </div>
        )}

        {dateType === "date" && (
          <div style={{ marginBottom: "12px" }}>
                    <label for="exampleInputEmail1">Select Full Date :</label>
            <input
              className="form-control"
              type="date"
              value={publishDate}
              onChange={(e) => {
                setpublishDate(e.target.value);
              }}
            />
          </div>
        )}


        <div className="form-group">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue=""
              id="featured"
              checked={isFeatured}
              onChange={(val)=>setIsFeatured(val.target.checked)}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Featured
            </label>
          </div>
       

        </div>
        <SelectableInput
        title="Add Video Search Tag"
        value={searchTag}
        onChange={(val)=>setSearchTag(val)}
        className="form-group"
        placeholder="Enter keyword(s) to search Videos "

        />

            <button style = {{textAlign:'left' , float:'right'}} onClick={() => {
                AddCollection()
            }} type="button" class="btn btn-warning">Submit</button>
      </div>
    </>
  )
}

export default Videos