
import React, { useState} from "react";

import HttpClientXml from "../../Utils/HttpClientXml";
import toast from 'react-hot-toast'
import HomeService from "../../Service/HomeService";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddThematicCollection = () => {

    const [name , setName] = useState('');
    const [summary , setSummary] = useState('');
    const [content , setContent] = useState("")
    const [caption , setCaption] = useState("");
    console.log(summary)
    const [image , setImage] = useState('');
    const [ imgLoader , setImgLoader] = useState(false);
    console.log("Image",image);

  const imageHandler = async (e) => {
    toast.success("Please upload images with a height of 260 pixels and a width of 300 pixels.")
    setImgLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);

    let res = await HttpClientXml.fileUplode("upload-Photocategory" , "POST" , data);
    if(res && res.status){
      toast.success("Image uploaded successfully");
      setImage(res?.doc?.url);
      setImgLoader(false);
    }else{
      toast.error("Failed to upload Image");
      setImgLoader(false);
    }
  };

const AddThematicCollection = () => {
    let data = {
      name:name,
      img:image,
      themeType:'others',
      summary:(summary.replace("<p>","").replace("</p>","")),
      caption:caption,
      content:content
    }

    if(name && summary && image && caption && content){
      // console.log("DATA", name, summary);
      HomeService.AddStartupsType(data)
      .then((res) => {
          console.log("Response Add Thematic tuype Other" , res);
          if(res && res.status){
              toast.success(res.message);
              setName("");
              setSummary("");
              let file = document.querySelector("#images");
              file.value = "";
              setContent("");
              setImage("");
              setCaption("");
          }else{
            toast.error(res?.message)
          }
      })
      .catch((err) => {
        console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEE");
          console.log(err);
      })
    }else{
      toast.error("All Fields Are Required")
    }


}

const HandleCrossClick = () => {
    setImage("");
    let file = document.querySelector("#images");
    file.value = "";
  }



const [activeTab, setActiveTab] = useState(0);
const tabCount = 3;
const handleNext = () => {
  setActiveTab((prevTab) => (prevTab === tabCount - 1 ? 0 : prevTab + 1));
};
const handleSkip = () => {
  setActiveTab((prevTab) => (prevTab === tabCount - 1 ? 0 : prevTab + 1));
};

const handleClicks = (num) => {
  setActiveTab(num);
}


  return (
    <>
             <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div style={{ textAlign: "center" , fontSize: "20px" , color:'#868e96',  margin:'35px'}} className="card-title">
            Add Thematic Collection
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Name<span style={{ color:'red'}}>*</span> :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter name..."
              />
            </div>

               <label for="exampleInputPassword1">Caption<span style={{ color:'red'}}>*</span> :</label>


                <div style={{ marginBottom: '21px'}}>
                <CKEditor
                    editor={ ClassicEditor }
                    data={caption}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }

                    onChange={(event, editor) => {
                   
                      let data = editor.getData();

                        setCaption(data);
                    
                    }}
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }

                    
                    
                />
                </div>

            <label for="exampleInputPassword1">Summary<span style={{ color:'red'}}>*</span> :</label>


                <div style={{ marginBottom: '21px'}}>
                <CKEditor
                    editor={ ClassicEditor }
                    data={summary}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }

                    onChange={(event, editor) => {
                      // let arr = [];
                      let data = editor.getData();
                      // let splits = data.split(/(\s+)/);
                      // let words = splits.filter((x) => x.trim().length>0);
                            // var count = words.length;
                      // if(words.length < 50){
                        setSummary(data);
                      // }else{
                      //   toast.error("Summary length should be less than 50 character");
                      // }
                    }}
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                    
                />
                </div>
                
            <div class="mb-3">
              <label for="formFile" class="form-label">
                Upload Image<span style={{ color:'red'}}>*</span> :
              </label>
              <input
                id="images"
                onChange={imageHandler}
                class="form-control"
                type="file"
                accept="image/*"
              />
              {/* {imgLoader ? <ImageLoader/> : null} */}
              {image &&
              <><img style={{ height: "30%", width: "30%" , marginTop:'12px' , borderRadius:'9px' }} src={image} />
                <button onClick={() => HandleCrossClick()} style={{ color: 'red'}} type="button" class="btn-close" aria-label="Close"></button>
              </>
               }
            </div>

            <div class="form-group">
          <label for="exampleInputEmail1">
            Content<span style={{ color: "red" }}>*</span> :
          </label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Enter content"
          />
        </div>

            <button  class="btn btn-primary" onClick={AddThematicCollection}>
              Submit
            </button>


            
          </div>
        </div>
      </div>

    </>
  )
}

export default AddThematicCollection