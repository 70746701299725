import React, { useEffect, useState } from "react";
import { Component } from 'react';
import HomeService from '../../Service/HomeService';
import HttpClientXml from "../../Utils/HttpClientXml";
import { toast } from "react-hot-toast";
import ImageLoader from "../../Loader/ImageLoader";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// const arr = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,]

const AddBlog = () => {

    const [name , setName] = useState('');
    const [summary , setSummary] = useState('');
    const [summaryValue , setSummaryValue] = useState("")
    const [image , setImage] = useState('');

    console.log("IMAGE" , image);
  
    // const [categoryName , setCategoryName] = useState('');
   
    const [Auther , setAuther] = useState('')
    const [ imgLoader , setImgLoader] = useState(false);
    const [categoryId , setCategoryId] = useState('')
    const [allCatName , setAllCatName] = useState([]);
    const [readTime , setReadTime] = useState('');


    useEffect(() => {
      fetchAllBlogCategory();
    },[]);
    const fetchAllBlogCategory = () =>{
      HomeService.ViewAllBlogCategory()
      .then((res) => {
          console.log("ResAllBlog" , res.data);
        if (res && res?.status) {

          setAllCatName(res?.data);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
      //   setLoader(false)
        console.log("err", err);
      });
    }


    const HandleAuthorName = (e)=>{
      const newInputValue = e.target.value.replace(/[^A-Za-z]/g, '');

        setAuther(newInputValue)
    }


   

    console.log("Image",image);

  const imageHandler = async (e) => {
    setImgLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);

    let res = await HttpClientXml.fileUplode("upload-blog-image" , "POST" , data);
    if(res && res.status){
      toast.success("Image uploaded successfully");
      setImage(res?.doc?.url);
      setImgLoader(false);
    }else{
      toast.error("Failed to upload Image");
      setImgLoader(false);
    }
  };

const AddBlog = () => {
    let data = {
        blogTitle:name,
        blogImage:image,
        description:(summary.replace("<p>","").replace("</p>","")),
        summary:(summaryValue.replace("<p>","").replace("</p>","")),
        category :categoryId,
        author: Auther,
        readingTime:readTime
    }

    if(name && summary && image && categoryId && Auther && summaryValue && readTime){
      HomeService.AddBlog(data)
      .then((res) => {
          console.log("Response Add Blog" , res);
          if(res && res.status){
              toast.success("Blog Added Successfully");
              setName("");
              setSummary("");
              let file = document.querySelector("#images");
              file.value = "";
              setImage("");
              setSummaryValue("");
              setReadTime("")
              setAuther("");
             setCategoryId("");
          }else{
            // console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEE");
            toast.error("Blog Name Already Exists ")
          }
      })
      .catch((err) => {
        console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEE");
          console.log(err);
      })
    }else{
      toast.error("All Fields Are Required");
    }
}

const handleBlogCategory = (e) => {
  setCategoryId(e?.target?.value)
}

const HandleCrossClick = () => {
    setImage("");
    let file = document.querySelector("#images");
    file.value = "";
  }
  return (
   
     <>
        <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div style={{ textAlign: "center" , fontSize: "20px" , color:'#868e96',  margin:'35px'}} className="card-title">
              Add Blog
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Title<span style={{ color:'red'}}>*</span> :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter name..."
              />
            </div>

            <label for="exampleInputPassword1">Description<span style={{ color:'red'}}>*</span> :</label>


                <div style={{ marginBottom: '21px'}}>
                <CKEditor
                    editor={ ClassicEditor }
                    data={summary}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }

                    onChange={(event, editor) => {
                   
                      let data = editor.getData();

                        setSummary(data);
                    
                    }}
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } } 
                />
                </div>


                <label for="exampleInputPassword1">Summary<span style={{ color:'red'}}>*</span> :</label>


<div style={{ marginBottom: '21px'}}>
<CKEditor
    editor={ ClassicEditor }
    data={summaryValue}
    // onReady={ editor => {
    //     You can store the "editor" and use when it is needed.
    //     console.log( 'Editor is ready to use!', editor );
    // } }

    onChange={(event, editor) => {
   
      let data = editor.getData();

      setSummaryValue(data);
    
    }}
    onBlur={ ( event, editor ) => {
        console.log( 'Blur.', editor );
    } }
    onFocus={ ( event, editor ) => {
        console.log( 'Focus.', editor );
    } }  
/>
</div>


                {/* <div class="form-group">
              <label for="exampleInputEmail1">Category Name<span style={{ color:'red'}}>*</span> :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                placeholder="Enter category name..."
              />
            </div> */}

        <label for="exampleInputEmail1">
          Category Name<span style={{ color: "red" }}>*</span> :
        </label>

        <select
          style={{ marginBottom: "21px" }}
          class="form-select"
          aria-label="select category"
          value={categoryId}
          onChange={(e) => handleBlogCategory(e)}
        >
          <option value={""}>Select a blog category name.......</option>
          {allCatName.map((item) => {
            return (
              <option id={item?._id} value={item?._id}>
                {item?.name}
              </option>
            );
          })}
        </select>



            <div class="form-group">
              <label for="exampleInputEmail1">Author<span style={{ color:'red'}}>*</span> :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={Auther}
                onChange={HandleAuthorName}
                    // const newInputValue = event.target.value.replace(/[^A-Za-z]/g, '');

                placeholder="Enter author name..."
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">Read time <span style={{ color:'red'}}>*</span> :</label>
              <select class="form-select" value={readTime} onChange={(e) => setReadTime(e.target.value)} aria-label="Default select example">
  <option value="">Select</option>
  {/* {
    
  } */}
  {[...Array(30)].map((_, i) => {
    return(
        <option value={i+1} key={i}> {i+1}</option>)
  })}

</select>
            </div>

            
                
            <div class="mb-3">
              <label for="formFile" class="form-label">
                Upload Image<span style={{ color:'red'}}>*</span> :
              </label>
              <input
                id="images"
                onChange={imageHandler}
                class="form-control"
                type="file"
                accept="image/*"
              />
              {imgLoader ? <ImageLoader/> : null}
              {image &&
              <><img style={{ height: "10%", width: "10%" , marginTop:'12px' , borderRadius:'9px' }} src={image} />
                <button onClick={() => HandleCrossClick()} style={{ color: 'red'}} type="button" class="btn-close" aria-label="Close"></button>
              </>
               }
            </div>

            <button  class="btn btn-primary" onClick={AddBlog}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
 
  )
}

export default AddBlog