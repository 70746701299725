import React, { useEffect, useState } from "react";
import { Component } from 'react';
import HomeService from '../../Service/HomeService';
import HttpClientXml from "../../Utils/HttpClientXml";
import { toast } from "react-hot-toast";
import ImageLoader from "../../Loader/ImageLoader";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";



const EditBlog = () => {
    const [name , setName] = useState('');
    const [summary , setSummary] = useState('');
    const [image , setImage] = useState('');
    // const [categoryName , setCategoryName] = useState('');
    const [Auther , setAuther] = useState('')
    const [summaryValue , setSummaryValue] = useState("")
    const [categoryId , setCategoryId] = useState('')
    const [allCatName , setAllCatName] = useState([]);

    const [ imgLoader , setImgLoader] = useState(false);
    const [readTime , setReadTime] = useState('');

    const location = useLocation();
    const navigate = useNavigate();

    console.log("Image",image);

    useEffect(() => {
      fetchAllBlogCategory();
        console.log("Location" , location?.state);
        if(location?.pathname == "/edit-blog"){
            setName(location?.state?.blogTitle);
            setSummary(location?.state?.description);
            setImage(location?.state?.blogImage);
            setCategoryId(location?.state?.category);
            setAuther(location?.state?.author);
            setSummaryValue(location?.state?.summary)
            setReadTime(location?.state?.readingTime)
        }
        
    },[]);


    const fetchAllBlogCategory = () =>{
      HomeService.ViewAllBlogCategory()
      .then((res) => {
          console.log("ResAllBlog" , res.data);
        if (res && res?.status) {

          setAllCatName(res?.data);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
      //   setLoader(false)
        console.log("err", err);
      });
    }

  const imageHandler = async (e) => {
    setImgLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);

    let res = await HttpClientXml.fileUplode("upload-blog-image" , "POST" , data);
    if(res && res.status){
      toast.success("Image uploaded successfully");
      setImage(res?.doc?.url);
      setImgLoader(false);
    }else{
      toast.error("Failed to upload Image");
      setImgLoader(false);
    }
  };

const EditBlog = () => {
    let data = {
        blogTitle:name,
        blogImage:image,
        description :(summary.replace("<p>","").replace("</p>","")),
        summary:(summaryValue.replace("<p>","").replace("</p>","")),
        category :categoryId,
        author: Auther,
        readingTime:readTime

    }

    if(summary && image && categoryId && Auther && summaryValue && readTime){
        console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
      HomeService.UpdateBlog( location?.state?._id ,data)
      .then((res) => {
          console.log("Response Edit Thematic Collection" , res);
          if(res && res.status){
              toast.success(res.message);
              setName("");
              setSummary("");
              let file = document.querySelector("#images");
              file.value = "";
              setImage("");
              setAuther("");
              setReadTime("");
              setSummaryValue("");
              setCategoryId("");
              navigate('/manage-blog');
          }else{
            console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEE");
            toast.error(res?.message)
          }
      })
      .catch((err) => {
        console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEE");
          console.log(err);
      })
    }else{
      toast.error("All Fields Are Required");
    }


}

const handleBlogCategory = (e) => {
  setCategoryId(e?.target?.value)
}

const HandleCrossClick = () => {
    setImage("");
    let file = document.querySelector("#images");
    file.value = "";
  }

  return (
    <>
    <div component="div" className="TabsAnimation appear-done enter-done">
<div className="main-card mb-3 card">
 <div className="card-body">
   <div style={{ textAlign: "center" , fontSize: "20px" , color:'#868e96',  margin:'35px'}} className="card-title">
     Edit Blog
   </div>
   <div class="form-group">
              <label for="exampleInputEmail1">Title<span style={{ color:'red'}}>*</span> :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter name..."
              />
            </div>


   <label for="exampleInputPassword1">Description<span style={{ color:'red'}}>*</span> :</label>


       <div style={{ marginBottom: '21px'}}>
       <CKEditor
           editor={ ClassicEditor }
           data={summary}
           onReady={ editor => {
            
               console.log( 'Editor is ready to use!', editor );
           } }

           onChange={(event, editor) => {
             
               setSummary(editor.getData());
      
           }}
           onBlur={ ( event, editor ) => {
               console.log( 'Blur.', editor );
           } }
           onFocus={ ( event, editor ) => {
               console.log( 'Focus.', editor );
           } }
           
       />
       </div>

       <label for="exampleInputPassword1">Summary<span style={{ color:'red'}}>*</span> :</label>


<div style={{ marginBottom: '21px'}}>
<CKEditor
    editor={ ClassicEditor }
    data={summaryValue}
    onReady={ editor => {
     
        console.log( 'Editor is ready to use!', editor );
    } }

    onChange={(event, editor) => {
      
      setSummaryValue(editor.getData());

    }}
    onBlur={ ( event, editor ) => {
        console.log( 'Blur.', editor );
    } }
    onFocus={ ( event, editor ) => {
        console.log( 'Focus.', editor );
    } }
    
/>
</div>


       {/* <div class="form-group">
     <label for="exampleInputEmail1">Category Name<span style={{ color:'red'}}>*</span> :</label>
     <input
       type="text"
       class="form-control"
       id="exampleInputEmail1"
       aria-describedby="emailHelp"
       value={categoryName}
       onChange={(e) => setCategoryName(e.target.value)}
       placeholder="Enter category name..."
     />
   </div> */}




<label for="exampleInputEmail1">
          Category Name<span style={{ color: "red" }}>*</span> :
        </label>

        <select
          style={{ marginBottom: "21px" }}
          class="form-select"
          aria-label="select category"
          value={categoryId}
          onChange={(e) => handleBlogCategory(e)}
        >
          <option value={""}>Select a blog category name.......</option>
          {allCatName.map((item) => {
            return (
              <option id={item?._id} value={item?._id}>
                {item?.name}
              </option>
            );
          })}
        </select>




   <div class="form-group">
     <label for="exampleInputEmail1">Auther<span style={{ color:'red'}}>*</span> :</label>
     <input
       type="text"
       class="form-control"
       id="exampleInputEmail1"
       aria-describedby="emailHelp"
       value={Auther}
       onChange={(e) => setAuther(e.target.value)}
       placeholder="Enter auther..."
     />
   </div>

   <div class="form-group">
              <label for="exampleInputEmail1">Read time <span style={{ color:'red'}}>*</span> :</label>
              <select class="form-select" aria-label="Default select example" value={readTime} onChange={(e) => setReadTime(e.target.value)}>
  <option value="">Select</option>
  {/* {
    
  } */}
  {[...Array(30)].map((_, i) => {
    return(
        <option value={i+1} key={i}> {i+1}</option>)
  })}

</select>
            </div>
       
   <div class="mb-3">
     <label for="formFile" class="form-label">
       Upload Image<span style={{ color:'red'}}>*</span> :
     </label>
     <input
       id="images"
       onChange={imageHandler}
       class="form-control"
       type="file"
       accept="image/*"
     />
     {imgLoader ? <ImageLoader/> : null}
     {image &&
     <><img style={{ height: "10%", width: "10%" , marginTop:'12px' , borderRadius:'9px' }} src={image} />
       <button onClick={() => HandleCrossClick()} style={{ color: 'red'}} type="button" class="btn-close" aria-label="Close"></button>
     </>
      }
   </div>

   <button  class="btn btn-primary" onClick={EditBlog}>
     Submit
   </button>
 </div>
</div>
</div>
</>
  )
}

export default EditBlog