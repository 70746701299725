import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useEffect } from "react";
import HomeService from "../../Service/HomeService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import Loader from "../../Loader/Loader";
import { useLocation } from "react-router-dom";

const ManagePhotography = () => {


  const [categoryPhotography, setCategoryPhotography] = useState([]);
  const [AllCatData , setAllCatData] = useState([]);
  const [collectionCategoryId , setCategoryCollectionId] = useState('');


  const [themeData , setThemeData] = useState([]);
  const [themeId , setThemeId] = useState('')

  const [loader , setLoader] = useState(false);

  const [hide , setHide] = useState(true);

  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    // setCategoryCollectionId(location?.state)
    fetchAllCollCatg();
    fetchThemeData()
    
  }, []);

  const fetchThemeData = () => {
    HomeService.AllThematicCollection()
        .then((res) => {
            console.log("ResThematicCollsadsd" , res.data);
          if (res && res?.status) {
            setThemeData(res?.data);
          }
      })
      .catch((err) => {
        
        console.log("err", err);
      });
  }

  const fetchAllCollCatg = () => {
    HomeService.AllCollectionCategory()
      .then((res) => {
        if (res && res?.status) {
            setAllCatData(res?.data)
        }
        console.log("RESCAT", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleCollCatName = (e) => {
    // setHide(false)
   
  //   setCategoryCollectionId((prev) => {
  //     let update = JSON.parse(JSON.stringify(prev));
  //     return update
  // });

    (e.target.value) === '' ? setCategoryCollectionId("") : fetchAllPhotography(e.target.value);
    setCategoryCollectionId(e.target.value) 
    setLoader(true)
}

  const onDelete = (id , catId) => {
    // console.log("DELETEITEM" , item);
    let data = {
      "isDeleted":true
    }

    Swal.fire({
      title: 'Are you sure?',
      // text: "You won't to delete this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.updatePhotoCategory(id, data)
        .then((res) => {
          console.log("RESPONSEDELETECOLL",res);
          if(res && res.status) {
            toast.success("Deleted successfully");
            fetchAllPhotography(catId)
          }else{
            toast.error("Failed to Delete the Item ")
          }
        })
        .catch((err) => {
          console.log(err);
        })
      }
    })


  }

  const onEdit = (item) => {
    navigate('/edit-photography', {state:item})
  }

 
  const fetchAllPhotography = (id) => {
    console.log("LLLLLLLLLLLLL");
  //   let data ={
  //     themeId: id
  // }

    
    HomeService.getAllPhotography(id)
      .then((res) => {
        console.log("responsePHOTOCATEGORY" , res);
        if (res && res?.status) {
          setLoader(false)
          let arr = res?.data?.map((item, index) => {
            return {
              sl: index + 1,
              catName: item?.category?.name,
              catType:item?.type?.name,
              title:item?.title,
              image:(
                <>
                {
                  (item?.image !== 'imag1') ? <img style={{ height: '45%', width: '45%' , borderRadius:'9px' , margin:"5px" }} src={item?.image} /> :
                  <img style={{ width: '22%' , borderRadius:'9px' , margin:"5px" }} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} />
                }
                </>
              ),
              desc:item?.desc,
              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <svg
                    onClick={() => onDelete(item?._id , item?.category?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </div>
              ),
            };
          });
          setCategoryPhotography(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        setLoader(false)
        console.log("err", err);
      });
  };

    const columns = [
        {
          name: <div style={{ fontSize: "14px",color:'#495057', fontWeight: "bolder" }}>SL</div>,
          selector: (row) => row.sl,
          sortable: true,
          width:"80px",
        },
        {
          name: (
            <div style={{ fontSize: "14px", color:'#495057',  fontWeight: "bolder" }}>
              Category Name
            </div>
          ),
          selector: (row) => row.catName,
        },
      //   {
      //     name: <div style={{ fontSize:'14px' ,color:'#495057', fontWeight:'bolder'}}>Category Type</div>,
      //     selector: row => row.catType,
      // },
        {
          name: (
            <div
              style={{ fontSize: "14px",color:'#495057', marginLeft: "15px", fontWeight: "bolder" }}
            >
              Title
            </div>
          ),
          selector: (row) => row.title,
        },            
        {
          name: (
            <div
              style={{ fontSize: "14px",color:'#495057', marginLeft: "15px", fontWeight: "bolder" }}
            >
              Desccription
            </div>
          ),
          selector: (row) => row.desc,
        },
        {
            name: (
              <div
                style={{ fontSize: "14px",color:'#495057', marginLeft: "15px", fontWeight: "bolder" }}
              >
                image
              </div>
            ),
            selector: (row) => row.image,
        },
        {
          name: (
            <div
              style={{ fontSize: "14px", color:'#495057', marginLeft: "15px", fontWeight: "bolder" }}
            >
              Action
            </div>
          ),
          selector: (row) => row.action,
        },
      ];

      const handletheme = (e) => {
        
        setThemeId(e.target.data)
        fetchAllPhotography(e.target.value);
      }

  return (
   <>
          <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
              }}
              className="card-title"
            >
               ALL PHOTOGRAPHY LIST 
            </div>

            <label for="exampleInputEmail1"> Category<span style={{ color:'red'}}>*</span> :</label>
            <select
          style={{ marginBottom: "21px" }}
          class="form-select"
          aria-label="select category"
          value={collectionCategoryId}
          onChange={(e) => handleCollCatName(e)}
        >
          <option value={""}>Select a category.......</option>
          {AllCatData.map((item) => {
            return (
              <option id={item?._id} value={item?._id}>
                {item?.name}
              </option>
            );
          })}
        </select>

        {/* <label for="exampleInputEmail1"> Theme :<span style={{ color:'red'}}>*</span> :</label>
          <select
          style={{ marginBottom: "21px" }}
          class="form-select"
          aria-label="select category"
          value={themeId}
          onChange={(e) => handletheme(e)}
          disabled={hide}
        >
          <option value={""}>Select a theme.......</option>
          {themeData.map((item) => {
            return (
              <option id={item?._id} value={item?._id}>
                {item?.name}
              </option>
            );
          })}
        </select> */}


         
          {collectionCategoryId ? loader ? <div style={{ textAlign: 'center'}}> <Loader/> </div>  : null : null}
          {/* {collectionCategoryId && themeId ? <DataTable columns={columns} data={categoryPhotography} pagination /> : null}   */}
          {collectionCategoryId ? <DataTable columns={columns} data={categoryPhotography} pagination /> : null }
          </div>
        </div>
      </div>
   </>
  )
}

export default ManagePhotography